import ServiceProvider from '../ServiceProvider'
const baseEndpoint = '/domain/watchlists'
class Watchlist {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    api.watchlist = this
    this.api = api

    this.getDefault = this.getDefault.bind(this)
    this.addToDefault = this.addToDefault.bind(this)
    this.removeFromDefault = this.removeFromDefault.bind(this)
    this.list = this.list.bind(this)
    this.get = this.get.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.removeSymbolFrom = this.removeSymbolFrom.bind(this)
    this.addSymbolTo = this.addSymbolTo.bind(this)
  }

  async getDefault(): Promise<any> {
    return await this.api.get(`${baseEndpoint}/default`)
  }

  async addToDefault(symbol: string): Promise<any> {
    return await this.api.post(`${baseEndpoint}/default/${symbol}`)
  }

  async removeFromDefault(symbol: string): Promise<any> {
    return await this.api.delete(`${baseEndpoint}/default/${symbol}`)
  }

  async list(): Promise<any> {
    return (await this.api.get(baseEndpoint)).data
  }

  async get(id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/${id}`)).data
  }

  async create(name: string, is_default = false): Promise<any> {
    return (await this.api.post(baseEndpoint, { is_default, name })).data
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async update(id: number, data: any): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/${id}`, data)).data
  }

  async delete(id: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/${id}`)).data
  }

  async removeSymbolFrom(id: number, symbol: string): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/${id}/${symbol}`)).data
  }

  async addSymbolTo(id: number, symbol: string): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/${id}/${symbol}`)).data
  }
}

export default Watchlist
