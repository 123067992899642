import { api } from '../classes/ServiceProvider'

export const getTradingAccounts = api.domain.trade.account.list.bind(api)
export const findTradingAccount = api.domain.trade.account.get.bind(api)
export const updateTradingAccount = api.domain.trade.account.update.bind(api)
export const deleteTradingAccount = api.domain.trade.account.delete.bind(api)
export const listTradingAccountEvents = api.domain.trade.account.listEvents.bind(api)
export const findTradingAccountEvents = api.domain.trade.account.getEvent.bind(api)
export const addTradingAccountEvent = api.domain.trade.account.addEvent.bind(api)
export const agreeTerm = api.domain.trade.account.agreeTerm.bind(api)
export const listPendingTerms = api.domain.trade.account.listPendingTerms.bind(api)
