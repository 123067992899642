import ServiceProvider from '../ServiceProvider'
const baseEndpoint = '/domain/explorer/filters'

class Explorer {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    api.explorer = this
    this.api = api

    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.get = this.get.bind(this)
    this.list = this.list.bind(this)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async create(payload: any): Promise<any> {
    return (await this.api.post(`${baseEndpoint}`, { ...payload })).data
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async update(payload: any): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/${payload.id}`, { ...payload })).data
  }

  async delete(filterId: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/${filterId}`)).data
  }

  async get(filterId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/${filterId}`)).data
  }

  async list(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}`)).data
  }
}

export default Explorer
