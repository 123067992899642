import "./App.css";
import { InstrumentsSummary } from "./components/InstrumentsSummary";
import { TVChartContainer } from "./components/TVChartContainer/index";
import logo from "./images/logo.svg";
import Browser from "./utils/browser";

const App = () => {
  const lock = Browser.getParameterByName("lock") === "true";
  const selectedProfile = Browser.getParameterByName("p") || null;
  const embedded = lock && selectedProfile;
  return (
    <div className={"App"}>
      {!embedded ? (
        <header className={"App-header"}>
          <img
            style={{
              width: "6rem",
              paddingTop: "-0.5rem",
              paddingRight: "1rem",
            }}
            alt="OpLab Logo"
            src={logo}
          />
          <h1 className={"App-title"}>
            <small>chart by </small>
            <span>
              <svg
                style={{ width: "1.929rem", height: "2rem" }}
                viewBox="0 0 36 28"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 22H7V11H0V4h14v18zM28 22h-8l7.5-18h8L28 22z"
                  fill="currentColor"
                ></path>
                <circle cx="20" cy="8" r="4" fill="currentColor"></circle>
              </svg>
            </span>
            <span>TradingView</span>
          </h1>
        </header>
      ) : null}
      <div className={`App-container ${embedded ? "embedded" : "regular"}`}>
        <TVChartContainer />
        {embedded ? null : <InstrumentsSummary />}
      </div>
    </div>
  );
};

export default App;
