import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import "../InstrumentsSummary/index.css";
import { api } from "@oplab-team/oplab-commons";
import { useEffect, useMemo, useState } from "react";
import {
  fulfillWatchlist,
  sortWatchlistInstruments,
  updateWatchlistInstruments,
} from "../../slices/applicationSlice";
import { formatNumber, formatPercent } from "../../utils/number";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { createSelector } from "@reduxjs/toolkit";
import datafeed from "@oplab-team/oplab-datafeed";

export const Watchlist = () => {
  const dispatch = useDispatch();
  const [sortDefinition, setSortDefinition] = useState({
    sortType: 1,
    column: "symbol",
  });

  const selectWatchlist = createSelector(
    (state: any) => state.application.watchlist,
    (watchlist: any) => watchlist
  );

  const watchlist = useSelector(selectWatchlist);

  const symbols = useMemo(() => {
    return (watchlist?.instruments ?? [])
      .map((instrument: any) => {
        return instrument.symbol;
      })
      .join(",");
  }, [watchlist?.instruments]);

  useEffect(() => {
    api.domain.watchlist.list().then((watchlists) => {
      dispatch(fulfillWatchlist(watchlists));
    });
  }, [dispatch]);

  useEffect(() => {
    dispatch(sortWatchlistInstruments(sortDefinition));
  }, [dispatch, sortDefinition]);

  useEffect(() => {
    let mounted = true;
    if (symbols) {
      datafeed
        .group("tick", "watchlist")
        .unsubscribe("*")
        .subscribe(symbols.split(","))
        .keys(true)
        .setInterval(2000)
        .listen((quotes: any) => {
          if (mounted) {
            dispatch(updateWatchlistInstruments({ quotes }));
          }
        });
    }

    return () => {
      mounted = false;
    };
  }, [symbols, dispatch]); // eslint-disable-line

  const sortIndicator = (sortType: number, show: boolean) =>
    show ? (
      <span style={{ paddingLeft: "0.1rem" }}>
        <FontAwesomeIcon icon={sortType === 1 ? faArrowUp : faArrowDown} />
      </span>
    ) : null;

  const selectChartInstrumentHandler = (symbol: string) => {
    const _window = window as any;
    if (_window?.oplab?.stockchart) {
      if (symbol) {
        _window?.oplab?.stockchart.setSymbol(symbol);
      }
    }
  };

  return (
    <div>
      <h5 className="item-title">Minha Lista</h5>
      <div className="items-container watchlist-container">
        <table className="watchlist-table">
          <thead>
            <tr>
              <th
                style={{ textAlign: "left" }}
                onClick={() => {
                  setSortDefinition({
                    sortType:
                      sortDefinition.column === "symbol"
                        ? sortDefinition.sortType * -1
                        : 1,
                    column: "symbol",
                  });
                }}
              >
                Código
                {sortIndicator(
                  sortDefinition.sortType,
                  sortDefinition.column === "symbol"
                )}
              </th>
              <th
                style={{
                  textAlign: "right",
                }}
                onClick={() => {
                  setSortDefinition({
                    sortType:
                      sortDefinition.column === "price"
                        ? sortDefinition.sortType * -1
                        : 1,
                    column: "price",
                  });
                }}
              >
                Preço
                {sortIndicator(
                  sortDefinition.sortType,
                  sortDefinition.column === "price"
                )}
              </th>
              <th
                style={{ textAlign: "right" }}
                onClick={() => {
                  setSortDefinition({
                    sortType:
                      sortDefinition.column === "variation"
                        ? sortDefinition.sortType * -1
                        : 1,
                    column: "variation",
                  });
                }}
              >
                Variação
                {sortIndicator(
                  sortDefinition.sortType,
                  sortDefinition.column === "variation"
                )}
              </th>
            </tr>
          </thead>
          <tbody>
            {(watchlist.instruments ?? []).map((instrument: any, j: number) => {
              const variationClass =
                instrument.variation !== 0
                  ? instrument.variation > 0
                    ? "positive-text"
                    : "negative-text"
                  : "";
              return (
                <tr
                  key={j}
                  onClick={() => {
                    selectChartInstrumentHandler(instrument.symbol);
                  }}
                >
                  <td
                    className={`watchlist-item-column ${variationClass}`}
                    style={{ textAlign: "left" }}
                  >
                    {instrument.symbol}
                  </td>
                  <td
                    className={`watchlist-item-column ${variationClass}`}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {formatNumber(instrument.price, 2)}
                  </td>
                  <td
                    className={`watchlist-item-column ${variationClass}`}
                    style={{
                      textAlign: "right",
                    }}
                  >
                    {formatNumber(instrument.variation * instrument.price, 2)}
                    {"  "} ({instrument.variation >= 0 ? "+" : ""}
                    {formatPercent(instrument.variation / 100, 2)})
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
