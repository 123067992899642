import config from "../config/config";

class SSO {
  constructor() {
    this.auth = null;
    this.accessToken = null;
  }

  token() {
    return this.accessToken;
  }

  init() {
    window.addEventListener(
      "message",
      ({ source, data, origin }) => {
        if (origin === config.ssoURL) {
          this.auth = source;
          this.accessToken = data?.token;
          if (this.accessToken)
            localStorage.setItem("access-token", window.oplab.token);
        }
      },
      false
    );

    const iframe = document.createElement("iframe");
    iframe.src = config.ssoURL;
    iframe.style.display = "none";
    window.document.body.appendChild(iframe);
  }
}

const singleton = new SSO();

export const sso = () => singleton.init();
export const ssoToken = () => singleton.token();
