import { api } from '../classes/ServiceProvider'

/**
 * Retrieves a list of all shared portfolios.
 * @param portfolio_id - The portfolio ID.
 * @returns {SharedPortfolioResponse[]}A promise that resolves to the list of shared portfolios.
 */
export const listAllSharings = api.domain.sharedPortfolio.list.bind(api)

/**
 * Retrieves a shared portfolio by its ID.
 * @param portfolio_id - The portfolio ID.
 * @param id - The ID of the shared portfolio.
 * @returns {SharedPortfolioResponse} A promise that resolves to the shared portfolio.
 */
export const readSharing = api.domain.sharedPortfolio.read.bind(api)

/**
 * Creates a new shared portfolio.
 * @param portfolio_id - The portfolio ID.
 * @param data - The data for creating the shared portfolio.
 * @returns {SharedPortfolioResponse} A promise that resolves to the created shared portfolio.
 */
export const createSharing = api.domain.sharedPortfolio.create.bind(api)

/**
 * Updates the access settings of a shared portfolio.
 * @param id - The ID of the shared portfolio.
 * @param access - The new access settings for the shared portfolio (read|write).
 * @returns {SharedPortfolioResponse} A promise that resolves to the updated shared portfolio.
 */
export const updateAccess = api.domain.sharedPortfolio.update.bind(api)

/**
 * Disables a shared portfolio.
 * @param id - The ID of the shared portfolio to disable.
 * @returns {any} - A promise that resolves when the shared portfolio is disabled.
 */
export const disableSharing = api.domain.sharedPortfolio.delete.bind(api)

/**
 * Retrieves a list of events associated with a shared portfolio.
 * @param portfolio_id - The portfolio ID.
 * @param id - The shared_portfolio ID
 * @returns {PortfolioEventResponse[]} A promise that resolves to the list of events.
 */
export const listEvents = api.domain.sharedPortfolio.listEvents.bind(api)

/**
 * Retrieves an event associated with a shared portfolio by its ID.
 * @param portfolio_id - The ID of the portfolio.
 * @param shared_portfolio_id - The ID of the shared_portfolio.
 * @param id - The ID of the event.
 * @returns {PortfolioEventResponse} A promise that resolves to the event.
 */
export const getEvent = api.domain.sharedPortfolio.getEvent.bind(api)
