import ServiceProvider from '../ServiceProvider'
const baseEndpoint = '/domain'

type Tag = string

type Strategy = {
  id: number
  name: string
  underlying: string
}

type Balance = {
  initial: number
  realized: number
  current: number
}

type Market = {
  open: number
  high: number
  low: number
  close: number
  vol: number
  fin_volume: number
  trades: number
  bid: number
  ask: number
  variation: number
  previous_close: number
}

type Info = {
  maturity_type?: string
  days_to_maturity?: number
  due_date?: Date
  strike?: number
  category?: string
  contract_size: number
  spot_price?: number
  has_options?: boolean
}

type VolRange = {
  max: number
  min: number
  percentile: number
  ranking: number
}

type VolPeriod = {
  one_y: VolRange
  six_m: VolRange
  current: number
}

type Quant = {
  ewma: VolPeriod
  iv: VolPeriod
  stdv: {
    one_y: number
    five_d: number
  }
  trend: {
    middle_term: number
    short_term: number
  }
  positive_scenario_probability: number
  semi_return: number
  beta_ibov: number
  correl_ibov: number
}

type UnderlyingAsset = {
  symbol: string
  name: string
  market: Market
  info: Info
  quant: Quant
  staged: boolean
}

export type Position = {
  id: number
  portfolio_id: number
  amount: number
  average_price: number
  weight: number
  tags: Tag[]
  strategy: Strategy
  balance: Balance
  exchange: string
  type: string
  name: string
  symbol: string
  robots: []
  underlying_asset?: UnderlyingAsset
  market: Market
  info: Info
  quant?: Quant | null
  staged: boolean
}

export type UserPortfolio = {
  id: number
  user_id: number
  created_at: Date
  updated_at: Date
  active: boolean
  name: string
  is_default: boolean
  replay_from: string | null
  security_category: string | null
  positions: Position[]
  balancings: []
}

type PortfolioPayload = {
  name: string
  active: boolean
}

class InstrumentService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.add = this.add.bind(this)
    this.update = this.update.bind(this)
    this.remove = this.remove.bind(this)
  }

  async add(id: string, payload: any): Promise<any> {
    return (
      await this.api.post(`${baseEndpoint}/portfolios/${id}/instruments`, {
        instrument: payload,
      })
    ).data
  }

  async update(id: number, { amount, price, symbol }: { amount: number; price: number; symbol: string }): Promise<any> {
    return (
      await this.api.put(`${baseEndpoint}/portfolios/${id}/instruments/${symbol}`, {
        instrument: { amount, price },
      })
    ).data
  }

  async remove(id: number, ticker: string): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${id}/instruments/${ticker}`)).data
  }
}

class PositionService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    // api.position = this
    this.api = api

    this.update = this.update.bind(this)
    this.consolidate = this.consolidate.bind(this)
    this.delete = this.delete.bind(this)
    this.close = this.close.bind(this)
    this.get = this.get.bind(this)
    this.getOrders = this.getOrders.bind(this)
    this.getByStrategy = this.getByStrategy.bind(this)
  }
  async update(position: {
    id: number
    name?: string
    positive_scenario_probability?: number
    portfolio_id: number
    strategy_id?: number
    strategy_name?: string
    orders?: any[]
  }): Promise<any> {
    return (
      await this.api.put(`${baseEndpoint}/portfolios/${position.portfolio_id}/positions/${position.id}`, position)
    ).data
  }

  async consolidate(portfolioId: number, positionId: number): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolioId}/positions/${positionId}/commit`)).data
  }

  async delete(portfolioId: number, positionId: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${portfolioId}/positions/${positionId}`)).data
  }

  async close(portfolioId: number, positionId: number, exercise = false): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolioId}/positions/${positionId}/close`, { exercise }))
      .data
  }

  async get(portfolioId: number, positionId: number): Promise<Position> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/positions/${positionId}`)).data
  }

  async getOrders(portfolioId: number, positionId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/positions/${positionId}/orders`)).data
  }

  async getByStrategy(portfolioId: number, strategyId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/strategies/${strategyId}`)).data
  }
}

class StrategyService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    this.consolidate = this.consolidate.bind(this)
    this.rename = this.rename.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.close = this.close.bind(this)
  }

  async consolidate(positionId: number, strategyId: number): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${positionId}/strategies/${strategyId}/commit`)).data
  }

  async rename(portfolio_id: number, strategy_id: number, name: string): Promise<any> {
    return (
      await this.api.put(`${baseEndpoint}/portfolios/${portfolio_id}/strategies/${strategy_id}/rename?name=${name}`)
    ).data
  }

  async update(position: any, newStrategy: any): Promise<any> {
    type Payload = {
      strategy_name?: string
      orders?: []
      strategy_id?: number
    }
    const payload: Payload = {}

    if ((newStrategy || {}).strategy_name) {
      payload.strategy_name = newStrategy.strategy_name
    }

    if ((newStrategy || {}).orders) {
      payload.orders = newStrategy.orders
    }

    if ((newStrategy || {}).strategy_id) {
      payload.strategy_id = newStrategy.strategy_id
    }

    return (await this.api.put(`${baseEndpoint}/portfolios/${position.portfolio_id}/positions/${position.id}`, payload))
      .data
  }

  async delete(portfolioId: number, strategyId: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${portfolioId}/strategies/${strategyId}`)).data
  }

  async close(positionId: number, strategyId: number, exercise = []): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${positionId}/strategies/${strategyId}/close`, { exercise }))
      .data
  }
}

class TagService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    this.list = this.list.bind(this)
    this.action = this.action.bind(this)
  }

  async list(portfolio_id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolio_id}/tags`)).data
  }

  async action(portfolio_id: number, position_id: number, tag: string, action: string): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolio_id}/positions/${position_id}/${action}/${tag}`))
      .data
  }
}

class Portfolio {
  instrument: InstrumentService
  position: PositionService
  strategy: StrategyService
  tag: TagService
  // balancing: any
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    api.portfolio = this
    this.api = api

    this.list = this.list.bind(this)
    this.get = this.get.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.reset = this.reset.bind(this)
    this.getReturn = this.getReturn.bind(this)
    this.createNewDefault = this.createNewDefault.bind(this)
    this.setDefault = this.setDefault.bind(this)
    this.instrument = new InstrumentService(api)
    this.position = new PositionService(api)
    this.strategy = new StrategyService(api)
    this.tag = new TagService(api)

    // this.balancing = {
    //   create: async (id: number, payload: any): Promise<any> =>
    //     (await api.post(`${baseEndpoint}/portfolios/${id}/balancings/`, payload)).data,

    //   update: async (id: number, payload: any): Promise<any> =>
    //     (await api.put(`${baseEndpoint}/portfolios/${id}/balancings/${payload.id}`, payload)).data,

    //   delete: async (id: number, payload: any): Promise<any> =>
    //     (await api.delete(`${baseEndpoint}/portfolios/${id}/balancings/${payload.id}`, payload)).data,
    // }
  }

  async list(type: string): Promise<UserPortfolio[]> {
    const parameters = type ? `type=${type}` : ''
    const queryString = parameters.length > 0 ? `?${parameters}` : ''

    return (await this.api.get(`${baseEndpoint}/portfolios${queryString}`)).data
  }

  async get(id: string): Promise<UserPortfolio> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${id}`)).data
  }

  async create(payload: PortfolioPayload): Promise<UserPortfolio> {
    return (await this.api.post(`${baseEndpoint}/portfolios`, payload)).data
  }

  async update(id: number, portfolio: PortfolioPayload): Promise<UserPortfolio> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${id}`, portfolio)).data
  }

  async delete(id: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${id}`)).data
  }

  async reset(portfolio_id: number, name: string): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolio_id}/reset`, { name })).data
  }

  async sync(portfolio_id: number): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolio_id}/synchronize`, { sync_strategy: 'B3' })).data
  }

  async deSync(portfolio_id: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${portfolio_id}/synchronize`)).data
  }

  async getReturn(id: number, from: string, to: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${id}/returns?from=${from || ''}&to=${to || ''}`)).data
  }

  async createNewDefault(name: string): Promise<any> {
    return this.api
      .post(`${baseEndpoint}/portfolios/`, { name, active: true })
      .then(({ data: portfolio }: { data: any }): Promise<any> => {
        return this.api
          .put(`${baseEndpoint}/portfolios/${portfolio.id}/default`)
          .then(({ status }: { status: number }): Promise<any> => (status === 200 ? portfolio : null))
      })
  }

  async setDefault(id: number): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${id}/default`)).data
  }

  async migrateFromVersion2(): Promise<any> {
    return this.api.put(`${baseEndpoint}/users/migrate_data`).then(({ status }: { status: number }) => status === 202)
  }
}

export default Portfolio
