import ServiceProvider from '../ServiceProvider'
const baseEndpoint = '/market'

class Ranking {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    api.ranking = this

    this.getHighestVolumeOptions = this.getHighestVolumeOptions.bind(this)
    this.getBestCoveredRatesOptions = this.getBestCoveredRatesOptions.bind(this)
    this.getHighestVariationOptions = this.getHighestVariationOptions.bind(this)
    this.getMostVolatileAssets = this.getMostVolatileAssets.bind(this)
    this.fetchFundamentalsRankings = this.fetchFundamentalsRankings.bind(this)
  }

  async getHighestVolumeOptions({ orderby, limit }: { orderby: string; limit: string }): Promise<any> {
    return (
      await this.api.get(
        `${baseEndpoint}/statistics/realtime/highest_options_volume?order_by=${orderby || 'total'}&limit=${
          limit || 20
        }`,
        [],
      )
    ).data
  }

  async getBestCoveredRatesOptions({ type, limit }: { type: string; limit: number }): Promise<any> {
    return (
      await this.api.get(
        `${baseEndpoint}/statistics/realtime/best_covered_options_rates/${type}?limit=${limit || 20}`,
        [],
      )
    ).data
  }

  async getHighestVariationOptions({ type, limit }: { type: string; limit: number }): Promise<any> {
    return (
      await this.api.get(
        `${baseEndpoint}/statistics/realtime/highest_options_variation/${type}?limit=${limit || 20}`,
        [],
      )
    ).data
  }

  async getMostVolatileAssets({
    rank_by,
    sort,
    limit,
    financial_volume_start,
  }: {
    rank_by: string
    sort: string
    limit: string
    financial_volume_start: number
  }): Promise<any> {
    return (
      await this.api.get(
        `${baseEndpoint}/stocks?rank_by=${rank_by || ''}&sort=${sort || ''}&limit=${
          limit || 20
        }&financial_volume_start=${financial_volume_start || ''}`,
        [],
      )
    ).data
  }

  async fetchFundamentalsRankings({ attribute, ...attrs }: { attribute: string; attrs: any }): Promise<any> {
    const query = Object.keys(attrs).reduce((acc: string, key: string) => {
      if (attrs[key]) {
        return `${acc}&${key}=${attrs[key]}`
      }
      return acc
    }, '')
    return (await this.api.get(`${baseEndpoint}/statistics/ranking/${attribute}?${query}`)).data
  }
}

export default Ranking
