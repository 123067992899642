import ServiceProvider from './ServiceProvider'
import Instrument from './market/Instrument'
import Ranking from './market/Ranking'

class Market {
  instrument: Instrument
  ranking: Ranking

  constructor(api: ServiceProvider) {
    this.instrument = new Instrument(api)
    this.ranking = new Ranking(api)
  }
}

export default Market
