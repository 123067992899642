import envConfig from '../config'
import Explorer from './domain/Explorer'
import Portfolio from './domain/Portfolio'
import Simulation from './domain/Simulation'
import User from './domain/User'
import Instrument from './market/Instrument'
import { Domain, Market } from './index'
import Ranking from './market/Ranking'
import Trade from './domain/Trade'
import Watchlist from './domain/Watchlist'

const fetch = typeof window === 'undefined' ? require('node-fetch').default : window.fetch

type Headers = {
  'Content-Type'?: string | null
  'access-token'?: string | null
  'X-OpLab-Client-Name'?: 'app' | 'pwa' | 'api'
  'X-OpLab-Client-Version'?: string
}

type Config = {
  headers?: Headers | undefined
  version?: number | undefined
}

type Api = {
  get: any
  post: any
  put: any
  delete: any
}

function updateRequestConfig(config: Config): Api {
  return {
    get: request('get', config),
    post: request('post', config),
    put: request('put', config),
    delete: request('delete', config),
  }
}

async function ensureContent(r: Response, defaultData: any = null) {
  const method = (await (r.headers.get('content-type') || '').match(/json/g)) ? 'json' : 'text'
  try {
    const data = await r[method]()

    const defaultResponse = { status: r.status, headers: r.headers, data, ok: r.ok }
    if (r.status >= 300 && r.status !== 422 && r.status !== 412) {
      throw new Error(data.error ?? r.statusText)
    }

    if (r.status === 204) {
      return { ...defaultResponse, data: defaultData || data }
    }

    return defaultResponse
  } catch (error: any) {
    throw { status: r.status, error: error.message }
  }
}

function request(method: string, config: Config, defaultData = null) {
  return async (url: string, body = {}, headers = {}) => {
    const h = {
      method,
      ...config,
      ...headers,
    }

    if (method !== 'get') {
      h['body'] = JSON.stringify(body)
    }

    const apiVersion = `v${config.version}`
    const response = await fetch(`${envConfig.baseURL}${apiVersion}${url}`, h)
    return await ensureContent(response, defaultData)
  }
}

class ServiceProvider {
  token: string
  version: number
  typed: boolean
  config: Config
  get: any
  post: any
  put: any
  delete: any
  explorer: Explorer | undefined
  portfolio: Portfolio | undefined
  simulation: Simulation | undefined
  trade: Trade | undefined
  watchlist: Watchlist | undefined
  user: User | undefined
  instrument: Instrument | undefined
  ranking: Ranking | undefined
  domain: Domain
  market: Market

  constructor() {
    this.token = 'token not defined, call .configure()'
    this.version = 3
    this.typed = false
    this.config = {
      headers: {
        'Content-Type': 'application/json',
        'access-token': this.token,
      },
    }

    const {
      get: _get,
      post: _post,
      put: _put,
      delete: _delete,
    } = updateRequestConfig({ ...this.config, version: this.version })

    this.get = _get.bind(this)
    this.post = _post.bind(this)
    this.put = _put.bind(this)
    this.delete = _delete.bind(this)

    this.domain = new Domain(this)
    this.market = new Market(this)
  }

  configure({
    token,
    version = 3,
    typed = false,
    headers = {
      'Content-Type': 'application/json',
      'X-OpLab-Client-Name': 'pwa',
      'X-OpLab-Client-Version': '1.0.0',
    },
  }: {
    token: string
    version: number
    typed: boolean
    headers: Headers
  }): any {
    this.token = token
    this.version = version
    this.typed = typed

    if (!this.config) this.config = {}
    if (!this.config?.headers) this.config.headers = {}

    for (const key in headers) {
      this.config.headers[key] = headers[key]
    }

    if (this.token) {
      this.config.headers['access-token'] = this.token
    }

    const {
      get: _get,
      post: _post,
      put: _put,
      delete: _delete,
    } = updateRequestConfig({ ...this.config, version: this.version })

    this.get = _get.bind(this)
    this.post = _post.bind(this)
    this.put = _put.bind(this)
    this.delete = _delete.bind(this)

    return this
  }
}

export const api: ServiceProvider = new ServiceProvider()

export default ServiceProvider
