import ServiceProvider from './ServiceProvider'
import Trade from './domain/Trade'
import Portfolio from './domain/Portfolio'
import Watchlist from './domain/Watchlist'
import User from './domain/User'
import Explorer from './domain/Explorer'
import Simulation from './domain/Simulation'
import Pep from './domain/Pep'
import SharedPortfolio from './domain/SharedPortfolio'

class Domain {
  explorer: Explorer
  portfolio: Portfolio
  simulation: Simulation
  trade: Trade
  user: User
  pep: Pep
  watchlist: Watchlist
  sharedPortfolio: SharedPortfolio

  constructor(api: ServiceProvider) {
    this.explorer = new Explorer(api)
    this.portfolio = new Portfolio(api)
    this.simulation = new Simulation(api)
    this.trade = new Trade(api)
    this.user = new User(api)
    this.pep = new Pep(api)
    this.watchlist = new Watchlist(api)
    this.sharedPortfolio = new SharedPortfolio(api)
  }
}

export default Domain
