import { api } from '../classes/ServiceProvider'

export const createExplorerFilter = api.domain.explorer.create.bind(api)

export const updateExplorerFilter = api.domain.explorer.update.bind(api)

export const deleteExplorerFilter = api.domain.explorer.delete.bind(api)

export const fetchExplorerFilter = api.domain.explorer.get.bind(api)

export const fetchExplorerFilters = api.domain.explorer.list.bind(api)
