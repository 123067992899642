import ServiceProvider from '../ServiceProvider'
import { format } from 'date-fns'

const baseEndpoint = '/market'

class ExchangeService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    this.get = this.get.bind(this)
    this.list = this.list.bind(this)
  }
  async get(symbol: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/exchanges/${symbol}`)).data
  }

  async list(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/exchanges`)).data
  }
}

class InterestRateService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.get = this.get.bind(this)
    this.list = this.list.bind(this)
  }
  async get(interestRate: string | number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/interest_rates/${interestRate}`)).data
  }

  async list(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/interest_rates`)).data
  }
}

class OptionService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.getDetails = this.getDetails.bind(this)
    this.list = this.list.bind(this)
    this.listPowders = this.listPowders.bind(this)
  }

  async getDetails(symbol: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/options/details/${symbol}`)).data
  }

  async list(symbol: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/options/${symbol}`)).data
  }

  async listPowders(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/options/powders`)).data
  }
}

class Instrument {
  exchange: ExchangeService
  interestRate: InterestRateService
  option: OptionService
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    api.instrument = this
    this.api = api

    this.search = this.search.bind(this)
    this.getChartData = this.getChartData.bind(this)
    this.getHistoricalData = this.getHistoricalData.bind(this)
    this.getVolChartData = this.getVolChartData.bind(this)
    this.getData = this.getData.bind(this)
    this.listQuotes = this.listQuotes.bind(this)
    this.listStocks = this.listStocks.bind(this)
    this.listCompanies = this.listCompanies.bind(this)
    this.listCoverages = this.listCoverages.bind(this)
    this.getSeries = this.getSeries.bind(this)
    this.exchange = new ExchangeService(api)
    this.interestRate = new InterestRateService(api)
    this.option = new OptionService(api)
  }

  async search(query: string, criteria: string): Promise<any> {
    try {
      const qs = Object.keys(criteria || {})
        .map((key: any) => `${key}=${criteria[key]}`)
        .join('&')
      return (await this.api.get(`${baseEndpoint}/instruments/search?expr=${query}&${qs}`)).data
    } catch (e) {
      return []
    }
  }

  async getChartData(symbol: string, from: string, to: string): Promise<any> {
    return await this.api.get(
      `/domain/charts/data/${symbol}/1d?from=${format(new Date(from), 'yyyyMMdd0000')}&to=${format(
        new Date(to),
        'yyyyMMdd0000',
      )}&fill=business_days`,
    )
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getHistoricalData(symbol: string, params: any): Promise<any> {
    const { from, to, amount } = params
    const dataInterval =
      from && to
        ? `from=${format(new Date(from), 'yyyyMMdd0000')}&to=${format(new Date(to), 'yyyyMMdd0000')}`
        : `amount=${amount}`

    return (await this.api.get(`${baseEndpoint}/charts/data/${symbol}/1d?${dataInterval}&fill=business_days`)).data
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async getVolChartData(symbol: string, params: any): Promise<any> {
    const { from, to, amount, type } = params
    const dataInterval =
      from && to
        ? `from=${format(new Date(from), 'yyyyMMdd0000')}&to=${format(new Date(to), 'yyyyMMdd0000')}`
        : `amount=${amount}`
    return (
      await this.api.get(
        `${baseEndpoint}/historical/${symbol}${type || 'IVX'}/1d?${dataInterval}&fill=business_days&smooth=true`,
      )
    ).data
  }

  //type can be s for single  or m for multiple
  async getData(symbol: string, type = 's'): Promise<any> {
    if (type.toLowerCase() === 's') {
      return (await this.api.get(`${baseEndpoint}/instruments/${symbol}`)).data
    }

    if (type.toLocaleLowerCase() === 'm') {
      return (await this.api.get(`${baseEndpoint}/instruments?tickers=${symbol}`)).data
    }

    return null
  }

  async listQuotes(symbols: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/data/quote?tickers=${symbols}`)).data
  }

  async listStocks(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/stocks`)).data
  }

  async listCompanies(symbols: string, includes: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/companies?symbols=${symbols || ''}&includes=${includes || ''}`)).data
  }

  async listCoverages(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/options/strategies/covered`)).data
  }

  async getSeries(symbol: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/instruments/series/${symbol}`)).data
  }
}

export default Instrument
