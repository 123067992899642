import config from "../config/config";

const { format } = require("date-fns");

const fetch =
  typeof window === "undefined" ? require("node-fetch") : window.fetch;

const Provider = {};

const domain = `${config.apiURL}/${config.apiVersion}`;

Provider.ServiceProvider = function (options) {
  this.token = options?.accessToken || "";
  this._headers = {
    "Content-Type": "application/json",
    "Access-Token": this.token,
  };
};

Provider.ServiceProvider.prototype.authorize = async function () {
  const response = await fetch(`${domain}/domain/users/authorize?for=chart`, {
    method: "GET",
    headers: this._headers,
    mode: "cors",
    cache: "default",
  });

  return await response.json();
};

Provider.ServiceProvider.prototype.getChartDataInfo = async function () {
  const response = await fetch(`${domain}/domain/charts/data/info`, {
    method: "GET",
    headers: this._headers,
    mode: "cors",
    cache: "default",
  });
  return response.json();
};

Provider.ServiceProvider.prototype.getChartInstrumentData = async function (
  symbolName
) {
  const response = await fetch(
    `${domain}/domain/charts/instruments/${symbolName}`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );

  if (response.status === 200) {
    return await response.json();
  }
  return null;
};

Provider.ServiceProvider.prototype.searchInstrument = async function (
  userInput,
  exchange,
  symbolType
) {
  const response = await fetch(
    `${domain}/domain/charts/instruments/search/${userInput}?exchange=${exchange}&type=${symbolType}`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );

  return await response.json();
};

Provider.ServiceProvider.prototype.getInstrumentInfo = async function (symbol) {
  const response = await fetch(
    `${domain}/market/instruments/${symbol.toUpperCase()}`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );

  return await response.json();
};

Provider.ServiceProvider.prototype.getChartBars = async function (
  ticker,
  resolution,
  from,
  to
) {
  const response = await fetch(
    `${domain}/domain/charts/data/${ticker}/${resolution}?from=${from}&to=${to}`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );

  return response.json();
};

Provider.ServiceProvider.prototype.getChartBarsSample = async function (
  ticker,
  resolution
) {
  const response = await fetch(
    `${domain}/domain/charts/data/${ticker}/${resolution}?amount=1000`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );

  return response.json();
};

Provider.ServiceProvider.prototype.getSeries = async function (
  symbol,
  resolution,
  from,
  to,
  firstDataRequest
) {
  const response = await fetch(
    `${domain}/domain/charts/data/${symbol}/${resolution}?from=${format(
      from,
      "yyyyMMddhhMM"
    )}&to=${firstDataRequest ? "" : format(to, "yyyyMMddhhMM")}`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );

  return response.json();
};

Provider.ServiceProvider.prototype.getServerTime = async function () {
  const response = await fetch(`${domain}/domain/charts/data/time`, {
    method: "GET",
    headers: this._headers,
    mode: "cors",
    cache: "default",
  });

  return await response.json();
};

Provider.ServiceProvider.prototype.getEvents = function (
  symbolInfo,
  resolution,
  from,
  to
) {
  return fetch(
    `${domain}/data/events/${symbolInfo.ticker}/${resolution}?from=${format(
      from,
      "yyyyMMddhhMM"
    )}&to=${format(to, "yyyyMMddhhMM")}`,
    {
      method: "GET",
      headers: this._headers,
      mode: "cors",
      cache: "default",
    }
  );
};

Provider.ServiceProvider.prototype.getExchanges = async function () {
  const response = await fetch(`${domain}/market/exchanges`, {
    method: "GET",
    headers: this._headers,
    mode: "cors",
    cache: "default",
  });

  return response.json();
};

Provider.ServiceProvider.prototype.getStoredStock = async function (symbol) {
  const response = await fetch(`${domain}/stocks/${symbol.toUpperCase()}`, {
    method: "GET",
    headers: this._headers,
    mode: "cors",
    cache: "default",
  });

  return response.json();
};

export default Provider;
