const Browser = {
  getCookieByName: function (k) {
    return (document.cookie.match("(^|; )" + k + "=([^;]*)") || 0)[2];
  },

  getParameterByName: function (name) {
    name = name.replace(/[[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    //return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    return results === null ? "" : decodeURIComponent(results[1]);
  },

  deleteCookieByName: function (name) {
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  },
};
export default Browser;
