import ServiceProvider from '../ServiceProvider'

class Simulation {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    api.simulation = this
    this.api = api

    this.createStrategy = this.createStrategy.bind(this)
    this.save = this.save.bind(this)
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async createStrategy(portfolioId: number, payload: any): Promise<any> {
    return (await this.api.post(`/domain/portfolios/${portfolioId}/strategies`, payload)).data
  }

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  async save(portfolioId: number, simulation: any): Promise<any> {
    if (!portfolioId) return new Promise((ok, reject) => reject({ error: 'Portfolio is not set' }))
    else return this.createStrategy(portfolioId, simulation)
  }
}

export default Simulation
