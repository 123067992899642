import { useDispatch, useSelector } from "react-redux";
import { Range } from "../Range";
import "../../theme/style.css";
import "./index.css";
import { formatNumber, formatPercent } from "../../utils/number";
import { securityCategories } from "../../utils/instrument";
import { format } from "date-fns";
import { updatePanel } from "../../slices/applicationSlice";
import { Watchlist } from "../Watchlist";
import { createSelector } from "@reduxjs/toolkit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { MarketStatus } from "../../slices/applicationSlice";

const volatilitySummary = [
  {
    range: {
      volType: "iv",
      periodPrefix: "1y",
      description: "Vol. Implícita",
      periodDescription: "1 ano",
    },
    items: [
      { prefix: "current", sufix: null, description: "atual" },
      // { prefix: null, sufix: "rank", description: "Rank" },
      // { prefix: null, sufix: "percentile", description: "Percentil" },
    ],
  },
  {
    range: {
      volType: "iv",
      periodPrefix: "6m",
      description: "Vol. Implícita",
      periodDescription: "6 meses",
    },
    items: [
      // { sufix: "rank", description: "Rank" },
      // { sufix: "percentile", description: "Percentil" },
    ],
  },

  {
    range: {
      volType: "ewma",
      periodPrefix: "1y",
      description: "Vol. Hist. EWMA",
      periodDescription: "1 ano",
    },
    items: [
      { prefix: "current", sufix: null, description: "atual" },
      // { prefix: null, sufix: "rank", description: "Rank" },
      // { prefix: null, sufix: "percentile", description: "Percentil" },
    ],
  },
  {
    range: {
      volType: "ewma",
      periodPrefix: "6m",
      description: "Vol. Hist. EWMA",
      periodDescription: "6 meses",
    },
    items: [
      // { sufix: "rank", description: "Rank" },
      // { sufix: "percentile", description: "Percentil" },
    ],
  },
];

const negotiationInfo = [
  {
    text: "Data",
    field: "last_trade_at",
    formatter: (value: any) =>
      value ? format(new Date(value), "dd/MM/yyyy") : "1",
  },
  {
    text: "Hora",
    field: "last_trade_at",
    formatter: (value: any) =>
      value ? format(new Date(value), "HH:mm:ss") : "",
  },
  {
    text: "Abertura",
    field: "open",
    formatter: (value: number) => formatNumber(value ?? 0, 2),
  },
  {
    text: "Máxima",
    field: "high",
    formatter: (value: number) => formatNumber(value ?? 0, 2),
  },
  {
    text: "Mínima",
    field: "low",
    formatter: (value: number) => formatNumber(value ?? 0, 2),
  },
  {
    text: "Último",
    field: "close",
    formatter: (value: number) => formatNumber(value ?? 0, 2),
  },
  {
    text: "Volume",
    field: "volume",
    formatter: (value: number) => formatNumber(value ?? 0, 0),
  },
  {
    text: "Volume financeiro",
    field: "financial_volume",
    formatter: (value: number) => formatNumber(value ?? 0, 2),
  },
];

export const InstrumentsSummary = () => {
  const selectInstumentInfo = createSelector(
    (state: any) => state.application.instrument,
    (instrument: any) => instrument
  );

  const selectMarketStatus = createSelector(
    (state: any) => state.application.market,
    (market: any) => market.status
  );

  const selectPanelDetails = createSelector(
    (state: any) => state.application.panels,
    (panels: any) => panels.details
  );

  const instrument = useSelector(selectInstumentInfo);
  const details = useSelector(selectPanelDetails);
  const marketStatus = useSelector(selectMarketStatus);

  const { render: shouldRenderPanel, show: shouldShowPanel } = details;

  const dispatch = useDispatch();
  const { symbol, sector, close, variation } = instrument;

  const translateMarket = {
    [MarketStatus.Open]: { text: "tempo real", class: "marketOn" },
    [MarketStatus.Closed]: { text: "mercado fechado", class: "marketOff" },
    [MarketStatus.Auction]: { text: "leilão", class: "marketTransition" },
  };
  return shouldShowPanel ? (
    <div className="main-container">
      <div className="main-content">
        {instrument.symbol !== "" && shouldRenderPanel ? (
          <div className="panel">
            <div className="instrument">
              <div className="main-title">
                {symbol}
                <span
                  className="close"
                  onClick={() => {
                    dispatch(updatePanel({ name: "details", show: false }));
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} size="lg" />
                </span>
              </div>
              <div className="main-subtitle">
                <span title="Setor">
                  {sector ?? securityCategories[instrument.security_category]}
                </span>
              </div>
            </div>
            <div className="indicator indicator-price-container">
              <div className="price">
                {formatNumber(close ?? 0, 2)}{" "}
                <span
                  className={`variation${
                    variation < 0 ? " negative-text" : " positive-text"
                  }`}
                >
                  {variation > 0 ? "+" : ""}
                  {formatPercent((variation ?? 0) / 100, 2)}
                </span>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  className={`circle ${
                    translateMarket[
                      marketStatus as keyof typeof translateMarket
                    ]?.class ?? translateMarket[MarketStatus.Closed].class
                  }`}
                  style={{ marginTop: "0.05rem", marginLeft: "-1rem" }}
                />
                <div className="market-status">
                  {translateMarket[marketStatus as keyof typeof translateMarket]
                    ?.text ?? translateMarket[MarketStatus.Closed].text}
                </div>
              </div>
            </div>
            <div className="volatility">
              {volatilitySummary.map((vol: any, i: number) => {
                const { range, items } = vol;
                const {
                  volType,
                  periodPrefix,
                  description,
                  periodDescription,
                } = range;

                const current = instrument[`${volType}_current`];
                const volMin = instrument[`${volType}_${periodPrefix}_min`];
                const volMax = instrument[`${volType}_${periodPrefix}_max`];

                return (
                  <div
                    key={i}
                    className={`${items.length > 0 ? "items-group" : ""}`}
                  >
                    {items.map((item: any, j: number) => {
                      const volKey = `${volType}${
                        item.prefix ? "_" + item.prefix : "_" + periodPrefix
                      }${item.sufix ? "_" + item.sufix : ""}`;
                      return (
                        <div
                          key={j}
                          className="item-container"
                          style={{ paddingBottom: "0.5rem" }}
                        >
                          <span className="item-left">{description}</span>
                          <span className="item-right">
                            <span style={{ fontWeight: "bold" }}>
                              {formatNumber(instrument[volKey] ?? 0, 2)}
                            </span>{" "}
                            ({item.description})
                          </span>
                        </div>
                      );
                    })}
                    <Range
                      value={((current - volMin) / (volMax - volMin)) * 100}
                    />
                    <div>
                      <span className="range-value" style={{ float: "left" }}>
                        {formatNumber(volMin ?? 0, 2)}
                      </span>
                      <span className="range-text">{periodDescription}</span>
                      <span className="range-value" style={{ float: "right" }}>
                        {formatNumber(volMax ?? 0, 2)}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="negotiation">
              <h5 className="item-title">Negócios</h5>
              <div className="items-container">
                {negotiationInfo.map((item: any, j: number) => {
                  return (
                    <div key={j} className="item-container item-border">
                      <span className="item-left item-left-normal">
                        {item.text}
                      </span>
                      <span className="item-right">
                        {item.formatter(instrument[item.field])}
                      </span>
                    </div>
                  );
                })}
              </div>
            </div>
            <Watchlist />
          </div>
        ) : null}
      </div>
    </div>
  ) : null;
};
