import { queryBuilder } from '../../utils/queryBuilder'
import ServiceProvider from '../ServiceProvider'

const baseEndpoint = '/domain'

const orderAdapter = (order: any): Promise<any> => {
  const adaptedOrder = {
    order_type: order.orderType,
    broker_id: order.brokerId,
    position_id: order.positionId,
    trigger_price: order.triggerPrice,
    expires_at: order.expiresAt,
    trading_account_id: order.tradingAccountId,
    executed_at: order.executedAt,
    ...order,
  }

  delete adaptedOrder.orderType
  delete adaptedOrder.brokerId
  delete adaptedOrder.positionId
  delete adaptedOrder.triggerPrice
  delete adaptedOrder.expiresAt
  delete adaptedOrder.tradingAccountId
  delete adaptedOrder.executedAt

  return adaptedOrder
}

const robotAdapter = (robot: any): Promise<any> => {
  const adaptedRobot = {
    trading_account_id: robot.tradingAccountId,
    fishing_size: robot.fishingSize,
    portfolio_id: robot.portfolioId,
    trigger_price: robot.triggerPrice,
    expire_date: robot.expireDate,
    start_time: robot.startTime,
    stop_time: robot.stopTime,
    ...robot,
  }

  delete adaptedRobot.tradingAccountId
  delete adaptedRobot.fishingSize
  delete adaptedRobot.portfolioId
  delete adaptedRobot.triggerPrice
  delete adaptedRobot.expireDate
  delete adaptedRobot.startTime
  delete adaptedRobot.stopTime

  return adaptedRobot
}

class BrokerService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    this.list = this.list.bind(this)
    this.get = this.get.bind(this)
    this.signUpUser = this.signUpUser.bind(this)
  }

  async list(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/brokers`)).data
  }

  async get(id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/brokers/${id}`)).data
  }

  async signUpUser(id: number, payload: any): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/brokers/${id}/sign_up`, payload)).data
  }
}

class OrderService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.create = this.create.bind(this)
    this.createMany = this.createMany.bind(this)
    this.read = this.read.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.list = this.list.bind(this)
    this.countOpen = this.countOpen.bind(this)
    this.exportHistorical = this.exportHistorical.bind(this)
  }

  async create(portfolioId: number, positionId: number, order: any): Promise<any> {
    if (portfolioId) {
      if (positionId) {
        return (
          await this.api.post(
            `${baseEndpoint}/portfolios/${portfolioId}/positions/${positionId}/orders`,
            orderAdapter(order),
          )
        ).data
      }
      return (await this.api.post(`${baseEndpoint}/portfolios/${portfolioId}/orders`, orderAdapter(order))).data
    }
  }

  async createMany(portfolioId: number, orders: []): Promise<any> {
    if (portfolioId) {
      return (
        await this.api.post(
          `${baseEndpoint}/portfolios/${portfolioId}/orders`,
          orders.map((order: any): Promise<any> => orderAdapter(order)),
        )
      ).data
    }
  }

  async read(portfolioId: number, orderId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/orders/${orderId}`)).data
  }

  async update(portfolioId: number, orderId: number, order: any): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolioId}/orders/${orderId}`, orderAdapter(order))).data
  }

  async delete(portfolioId: number, orderId: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${portfolioId}/orders/${orderId}`)).data
  }

  async list(
    portfolioId: number,
    from: string,
    to: string,
    origin: string,
    page: string,
    per: string,
    orderType: string,
    status: string,
    ticker: string,
    side: string,
    fullResponse = false,
  ): Promise<any> {
    const res = await this.api.get(
      `${baseEndpoint}/portfolios/${portfolioId}/orders${queryBuilder({
        from,
        to,
        origin,
        page,
        per,
        orderType,
        status,
        ticker,
        side,
      })}`,
    )
    return fullResponse ? res : res.data
  }

  async countOpen(portfolioId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/orders/pending`)).data
  }

  async exportHistorical(portfolioId: number): Promise<any> {
    return await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/orders/export`)
  }
}

class AccountService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    this.list = this.list.bind(this)
    this.get = this.get.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.listEvents = this.listEvents.bind(this)
    this.listPendingTerms = this.listPendingTerms.bind(this)
    this.getEvent = this.getEvent.bind(this)
    this.addEvent = this.addEvent.bind(this)
    this.agreeTerm = this.agreeTerm.bind(this)
    this.listPendingTerms = this.listPendingTerms.bind(this)
  }

  async list(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/trading_accounts`)).data
  }

  async get(id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/trading_accounts/${id}`)).data
  }

  async update(id: number, payload: any): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/trading_accounts/${id}`, payload)).data
  }

  async delete(id: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/trading_accounts/${id}`)).data
  }

  async listEvents(id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/trading_accounts/${id}/events`)).data
  }

  async getEvent(trading_account_id: number, id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/trading_accounts/${trading_account_id}/events/${id}`)).data
  }

  async addEvent(id: number, payload: any): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/trading_accounts/${id}/events`, payload)).data
  }

  async agreeTerm(id: number, termId: number): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/trading_accounts/${id}/agree/${termId}`, {})).data
  }

  async listPendingTerms(id: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/trading_accounts/${id}/pending_terms`)).data
  }
}

class RobotService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.list = this.list.bind(this)
    this.get = this.get.bind(this)
    this.create = this.create.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.pause = this.pause.bind(this)
    this.resume = this.resume.bind(this)
  }

  async list(portfolioId: number, status: string, from: string, to: string, page: string, per: string): Promise<any> {
    return (
      await this.api.get(
        `${baseEndpoint}/portfolios/${portfolioId}/robots${queryBuilder({ status, from, to, page, per })}`,
      )
    ).data
  }

  async get(portfolioId: number, robotId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/portfolios/${portfolioId}/robots/${robotId}`)).data
  }

  async create(portfolioId: number, robot: any): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/portfolios/${portfolioId}/robots`, robotAdapter(robot))).data
  }

  async update(portfolioId: number, { id, ...robot }: { id: number; robot: any }): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/portfolios/${portfolioId}/robots/${id}`, robotAdapter(robot))).data
  }

  async delete(portfolioId: number, robotId: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/portfolios/${portfolioId}/robots/${robotId}`)).data
  }

  async pause(portfolioId: number, robotId: number): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/portfolios/${portfolioId}/robots/${robotId}/pause`)).data
  }

  async resume(portfolioId: number, robotId: number): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/portfolios/${portfolioId}/robots/${robotId}/resume`)).data
  }
}

class Trade {
  broker: BrokerService
  order: OrderService
  account: AccountService
  robot: RobotService
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    api.trade = this
    this.broker = new BrokerService(api)
    this.order = new OrderService(api)
    this.robot = new RobotService(api)
    this.account = new AccountService(api)
  }
}

export default Trade
