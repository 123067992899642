import { api } from '../classes/ServiceProvider'

export const listPortfolios = api.domain.portfolio.list.bind(api)
export const findPortfolio = api.domain.portfolio.get.bind(api)
export const createPortfolio = api.domain.portfolio.create.bind(api)
export const update = api.domain.portfolio.update.bind(api)
export const deletePortfolio = api.domain.portfolio.delete.bind(api)
export const addInstrument = api.domain.portfolio.instrument.add.bind(api)
export const removeInstrument = api.domain.portfolio.instrument.remove.bind(api)
export const updateInstrument = api.domain.portfolio.instrument.update.bind(api)

export const getPortfolioReturn = api.domain.portfolio.getReturn.bind(api)
// export const newBalancing = api.domain.portfolio.balancing.create.bind(api)
// export const updateBalancing = api.domain.portfolio.balancing.update.bind(api)
// export const deleteBalancing = api.domain.portfolio.balancing.delete.bind(api)

export const getTags = api.domain.portfolio.tag.list.bind(api)

export const actionTag = api.domain.portfolio.tag.action.bind(api)

export const renamePositionStrategy = api.domain.portfolio.strategy.rename.bind(api)

export const resetPortfolio = api.domain.portfolio.reset.bind(api)

export const createNewDefaultPortfolio = api.domain.portfolio.createNewDefault.bind(api)

export const setDefaultPortfolio = api.domain.portfolio.setDefault.bind(api)

export const migratePortfolioFromVersion2 = api.domain.portfolio.migrateFromVersion2.bind(api)
