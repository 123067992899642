const config = {
  development: {
    loginURL: "https://www.oplab.com.br/login",
    apiURL: "https://lorraine.oplab.com.br",
    apiVersion: "v3",
    legacyApiVersion: "v2",
    stockChartURL: "https://stockchart-development.oplab.com.br",
    ssoURL: "https://sso-development.oplab.com.br/",
  },
  production: {
    loginURL: "https://www.oplab.com.br/login",
    apiURL: "https://api.oplab.com.br",
    apiVersion: "v3",
    legacyApiVersion: "v2",
    stockChartURL: "https://stockchart.oplab.com.br",
    ssoURL: "https://sso.oplab.com.br",
  },
};

export default config[process.env.REACT_APP_ENV];
