import {
  IPineStudyResult,
  LibraryPineStudy,
  RawStudyMetaInfoId,
  StudyLinePlotInfo,
  StudyLinePlotPreferences,
} from "../charting_library/charting_library";

const _name = "Volatilidade Implícita X Histórica";

export const VolatilityIndicator = (PineJS: any) => ({
  name: _name,
  metainfo: {
    _metainfoVersion: 51,
    id: "<oplab-ewma-vs-iv>@tv-basicstudies-1" as RawStudyMetaInfoId,
    scriptIdPart: "",
    description: _name,
    shortDescription: "Vol. Histórica X Implícita",
    is_hidden_study: false,
    is_price_study: false,
    isCustomIndicator: true,
    format: {
      type: "price" as const,
      precision: 1,
    },
    plots: [
      { id: "plot_0", type: "line" } as StudyLinePlotInfo,
      { id: "plot_1", type: "line" } as StudyLinePlotInfo,
    ],
    defaults: {
      styles: {
        plot_0: {
          linestyle: 0,
          visible: true,
          linewidth: 1,
          plottype: 2 as StudyLinePlotPreferences["plottype"],
          trackPrice: false,
          transparency: 10,
          color: "#de4956",
        },
        plot_1: {
          linestyle: 0,
          visible: true,
          linewidth: 1,
          plottype: 2 as StudyLinePlotPreferences["plottype"],
          trackPrice: false,
          transparency: 10,
          color: "#3dc2ff",
        },
      },
      inputs: {},
    },
    styles: {
      plot_0: {
        title: "Vol. EWMA",
        histogramBase: 0,
      },
      plot_1: {
        title: "Vol. Implícita",
        histogramBase: 0,
      },
    },

    inputs: [],
  },
  constructor: function (this: LibraryPineStudy<IPineStudyResult>) {
    this.init = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;
      const _ticker = this._context.symbol.ticker;
      const indicator_01 = _ticker + "IVX";
      const indicator_02 = _ticker + "EWMAB3";
      const volsSymbol = `${indicator_01}(close,open)+${indicator_02}(close,high)`;

      this._context.new_sym(
        volsSymbol,
        PineJS.Std.period(this._context),
        PineJS.Std.period(this._context)
      );
    };

    this.main = function (context, inputCallback) {
      this._context = context;
      this._input = inputCallback;

      this._context.select_sym(0);
      const mainSeries = this._context.new_var(this._context.symbol.time);
      this._context.select_sym(1);
      const volSeries = this._context.new_var(this._context.symbol.time);

      const vh = this._context.new_var(PineJS.Std.high(this._context));
      const vi = this._context.new_var(PineJS.Std.open(this._context));

      const _vi = vi.adopt(volSeries, mainSeries, 1);
      const _vh = vh.adopt(volSeries, mainSeries, 1);

      this._context.select_sym(0);

      return [_vh, _vi];
    };
  },
});
