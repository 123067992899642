// import { PayloadAction } from "@reduxjs/toolkit";
// import { put, takeLatest } from "redux-saga/effects";

// const SET_THEME = "@application/setTheme";

// export const actions = {
//   setTheme: (payload: string): PayloadAction<string> => ({
//     type: SET_THEME,
//     payload,
//   }),
// };

// export function* setTheme(action: PayloadAction<string>): any {
//   yield put(fb(theme))
// }

export function* instrumentSaga() {}
