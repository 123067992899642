import moment from "moment";

class OHLC {
  constructor(symbol, resolution, bar) {
    this.symbol = symbol;
    this.interval = 1;
    this.unit = "d";
    this.parentResolution = "1d";
    this.resolution = resolution;

    const groups = resolution.toLowerCase().match(/(\d+)*([hdwmy])*/);

    if (groups.length > 2) {
      this.interval = groups[1] || 1;
      switch (groups[2]) {
        case "d":
        case "w":
        case "y":
          this.unit = groups[2];
          break;
        case "m":
          this.unit = "M";
          break;
        case "h":
          this.interval *= 60;
          break;
        default: {
          this.unit = "m";
          this.parentResolution = "1"; // one minute
          this.interval *= 60;
        }
      }
    }

    this.initialize(bar);
  }

  initialize(bar) {
    if (bar) {
      this.currentBar = { ...bar, time: moment(bar.time) };
      this.nextBarAt = moment(bar.time).add(this.interval, this.unit).valueOf();
    } else {
      this.currentBar = null;
      this.nextBarAt = null;
    }
    this.pv = 0;
    this.pfv = 0;
    this.pt = null;
  }

  push(time, price, volume, fvolume) {
    this.save(time, price, price, price, price, volume, fvolume, true);
  }

  save(time, open, high, low, close, volume, fvolume, push = false) {
    if (!this.currentBar)
      this.initialize({ time, open, low, high, close, volume, fvolume });
    else {
      if (time >= this.nextBarAt) {
        let ctime = this.nextBarAt;
        while (time > ctime.valueOf()) {
          ctime = moment(ctime).add(this.interval, this.unit);
        }
        this.currentBar = {
          time: ctime,
          open: open,
          high: high,
          low: low,
          close: close,
          volume: volume,
          fvolume: fvolume,
        };
        this.pv = 0;
        this.pfv = 0;
        this.nextBarAt = moment(ctime).add(this.interval, this.unit);
      } else {
        this.currentBar.high = Math.max(this.currentBar.high, high);
        this.currentBar.low = Math.min(this.currentBar.low, low);
        this.currentBar.close = close;
        if (this.pt !== time) {
          this.pv = this.currentBar.volume;
          this.pfv = this.currentBar.fvolume;
        }
        if (push) {
          this.currentBar.volume += volume;
          this.currentBar.fvolume += fvolume;
        } else {
          this.currentBar.volume = this.pv + volume;
          this.currentBar.fvolume = this.pfv + fvolume;
        }
      }
    }
    this.pt = time;
    return { ...this.currentBar };
  }

  current() {
    return { ...this.currentBar };
  }

  serialize() {
    return JSON.stringify({
      symbol: this.symbol,
      name: this.symbol,
      resolution: this.resolution,
      data: [
        {
          ...this.currentBar,
          time: this.currentBar.time.valueOf(),
        },
      ],
    });
    //"PETR4","name":"PETROBRAS PN","resolution":"1d",
    //"data":[{"time":1550109600000,"close":26.56,"high":26.57,"low":26,"open":26.17,"volume":0}]}
  }

  getBar() {
    return {
      symbol: this.symbol,
      name: this.symbol,
      resolution: this.resolution,
      data: [
        {
          ...this.currentBar,
          time: this.currentBar.time.valueOf(),
        },
      ],
    };
  }

  getSymbol() {
    return this.symbol;
  }

  getParentResolution() {
    return this.parentResolution;
  }

  getResolution() {
    return this.resolution;
  }
}

export default OHLC;
