import { api } from '../classes/ServiceProvider'

export const search = api.market.instrument.search.bind(api)

export const getChartData = api.market.instrument.getChartData.bind(api)

export const getHistoricalData = api.market.instrument.getHistoricalData.bind(api)

export const getVolChartData = api.market.instrument.getVolChartData.bind(api)

export const getInstrumentData = api.market.instrument.getData.bind(api)
