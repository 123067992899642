import { api } from '../classes/ServiceProvider'

export const updatePosition = api.domain.portfolio.position.update.bind(api)
export const consolidatePosition = api.domain.portfolio.position.consolidate.bind(api)
export const deletePosition = api.domain.portfolio.position.delete.bind(api)
export const closePosition = api.domain.portfolio.position.close.bind(api)
export const consolidateStrategy = api.domain.portfolio.strategy.consolidate.bind(api)
export const updatePositionStrategy = api.domain.portfolio.strategy.update.bind(api)
export const deleteStrategy = api.domain.portfolio.strategy.delete.bind(api)
export const closeStrategy = api.domain.portfolio.strategy.close.bind(api)
export const fetchPositionOrders = api.domain.portfolio.position.getOrders.bind(api)
export const getPosition = api.domain.portfolio.position.get.bind(api)
export const fetchPositionsByStrategy = api.domain.portfolio.position.getByStrategy.bind(api)
