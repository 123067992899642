import { useEffect, useRef } from "react";
import "./index.css";
import {
  widget,
  ChartingLibraryWidgetOptions,
  LanguageCode,
  ResolutionString,
  IChartingLibraryWidget,
  CustomIndicator,
  IChartWidgetApi,
} from "../../charting_library";
import * as React from "react";
import Datafeeds from "../../services/datafeeds";
import { useDispatch } from "react-redux";
import {
  updateInstrument,
  updateMarketStatus,
  updatePanel,
  updateTheme,
  updateTick,
} from "../../slices/applicationSlice";
import { VolatilityIndicator } from "../../indicators/volatility";
import Browser from "../../utils/browser";
import { api } from "@oplab-team/oplab-commons";
import { ssoToken } from "../../utils/sso";
import config from "../../config/config";

export interface ChartContainerProps {
  symbol: ChartingLibraryWidgetOptions["symbol"];
  interval: ChartingLibraryWidgetOptions["interval"];

  // BEWARE: no trailing slash is expected in feed URL
  datafeedUrl: string;
  libraryPath: ChartingLibraryWidgetOptions["library_path"];
  chartsStorageUrl: ChartingLibraryWidgetOptions["charts_storage_url"];
  chartsStorageApiVersion: ChartingLibraryWidgetOptions["charts_storage_api_version"];
  clientId: ChartingLibraryWidgetOptions["client_id"];
  userId: ChartingLibraryWidgetOptions["user_id"];
  fullscreen: ChartingLibraryWidgetOptions["fullscreen"];
  autosize: ChartingLibraryWidgetOptions["autosize"];
  studiesOverrides: ChartingLibraryWidgetOptions["studies_overrides"];
  container: ChartingLibraryWidgetOptions["container"];
}

const getLanguageFromURL = (): LanguageCode | null => {
  const regex = new RegExp("[\\?&]lang=([^&#]*)");
  const results = regex.exec(window.location.search);
  return results === null
    ? null
    : (decodeURIComponent(results[1].replace(/\+/g, " ")) as LanguageCode);
};

const getTheme = (themeString: string | null) => {
  return themeString === "dark" ? "dark" : "light";
};

const DEFAULT_THEME = "light";

export const TVChartContainer = () => {
  const chartContainerRef =
    useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  // const customPaneRef =
  //   useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const dispatch = useDispatch();

  const selectedSymbol =
    Browser.getParameterByName("s") ||
    Browser.getParameterByName("symbol") ||
    null;
  const selectedTheme =
    Browser.getParameterByName("t") ||
    Browser.getParameterByName("theme") ||
    null;
  const lock = (Browser.getParameterByName("lock") || "").toLowerCase().match(/^true/);
  const selectedProfile = Browser.getParameterByName("p") || null;
  const embedded = lock && selectedProfile;

  const profile = embedded ? selectedProfile : null;

  const setupProfile = (profile: string | null, context: IChartWidgetApi) => {
    switch (profile) {
      case "oplab":
      case "embed":
        context.createStudy(
          "Bollinger Bands",
          false,
          false,
          { top: 8, bottom: 2 },
          {
            "upper.color": "#9d9fa1",
            "lower.color": "#9d9fa1",
            "median.color": "#eeeeef",
            "median.transparency": 100,
            "plots background.color": "#eeeeef",
            "plots background.transparency": 90,
          }
        );

        context.createStudy("Volatilidade Implícita X Histórica", false, false);
        break;
      default:
        return;
    }
  };

  const getChartOptionsByProfile = (
    profile: string | null
  ): Partial<ChartingLibraryWidgetOptions> => {
    switch (profile) {
      case "oplab":
      case "embed":
        return {
          timeframe: "6m",
          enabled_features: ["display_market_status"],
          disabled_features: [
            // "header_widget",
            "use_localstorage_for_settings",
            "header_screenshot",
            "timezone_menu",
            "border_around_the_chart",
            "remove_library_container_border",
            "header_symbol_search",
            "header_resolutions",
            "header_chart_type",
            "header_saveload",
            "header_chart_type",
            "header_indicators",
            "header_settings",
            "header_compare",
            "header_fullscreen_button",
            "header_undo_redo",
          ],
        };
      default:
        return {
          disabled_features: [
            "use_localstorage_for_settings",
            "header_screenshot",
            "timezone_menu",
          ],
          enabled_features: [
            "dont_show_boolean_study_arguments",
            "study_templates",
            "display_market_status",
          ],
          time_frames: [
            {
              text: "5Y",
              resolution: "W" as ResolutionString,
              description: "5 Anos",
            },
            {
              text: "1Y",
              resolution: "D" as ResolutionString,
              description: "1 Ano",
            },
            {
              text: "6M",
              resolution: "120" as ResolutionString,
              description: "6 Meses",
            },
            {
              text: "5D",
              resolution: "5" as ResolutionString,
              description: "3 Dias",
            },
            {
              text: "1D",
              resolution: "1" as ResolutionString,
              description: "1 Dia",
              title: "1d",
            },
          ],
          favorites: {
            intervals: [
              "15" as ResolutionString,
              "60" as ResolutionString,
              "1D" as ResolutionString,
            ],
          },
        };
    }
  };

  useEffect(() => {
    if (selectedTheme) {
      dispatch(updateTheme(selectedTheme));
    }
    document.body.className = selectedTheme ?? DEFAULT_THEME;
  }, [selectedTheme, dispatch]);

  useEffect(() => {
    let tvWidget: IChartingLibraryWidget;
    //IMPORTANT
    //I know this is not the best solution for make chart reference global
    //but it was the only way i have found
    //if you have a best solution feel free to change
    let _window = window as any;
    if (!_window.oplab) {
      _window.oplab = {};
    }

    setTimeout(() => {
      let token = ssoToken();

      token = !token ? localStorage.getItem("access-token") : token;
      token = !token ? Browser.getCookieByName("access-token") : token;

      if (!token) {
        token = Browser.getParameterByName("access-token");
        if (!token) {
          window.location.replace(config.loginUrl as string);
        }
      }

      if (token) {
        api.configure({
          token,
          version: (config.apiVersion ?? "v3").slice(1),
        } as any);
        api.domain.user
          .authorize()
          .then((credentials) => {
            const tvDatafeed = new Datafeeds.MangoDataFeed({
              url: `${config.apiURL}/${config.apiVersion}`,
              socketUrl: credentials.endpoints[0],
              storage: {
                url: `${config.apiURL}/${config.apiVersion}`,
                accessToken: credentials["access-token"],
              },
              accessToken: credentials["access-token"],
              datafeedToken: credentials["datafeed-access-token"],
              endpoints: credentials.endpoints,
            });

            tvDatafeed.on("datafeed.instrument.info", (instrument: any) => {
              dispatch(updateInstrument(instrument));
            });

            tvDatafeed.on("datafeed.instrument.updateTick", (tick: any) => {
              dispatch(updateTick(tick));
            });

            tvDatafeed.on("datafeed.market.status", (market: any) => {
              dispatch(updateMarketStatus(market));
            });

            const widgetOptions: ChartingLibraryWidgetOptions = {
              symbol: selectedSymbol ?? "BOVA11",
              // BEWARE: no trailing slash is expected in feed URL
              // tslint:disable-next-line:no-any
              //   datafeed: new (window as any).Datafeeds.UDFCompatibleDatafeed(
              //     defaultProps.datafeedUrl
              //   ),
              datafeed: tvDatafeed,
              interval: "D" as ResolutionString,
              container: chartContainerRef.current,
              library_path: "/charting_library/",
              locale: getLanguageFromURL() || "pt",
              charts_storage_url: config.apiURL,
              charts_storage_api_version: config.legacyApiVersion,
              custom_indicators_getter: (PineJS) => {
                return Promise.resolve<CustomIndicator[]>([
                  VolatilityIndicator(PineJS),
                ]);
              },
              client_id: "oplab.com.br",
              user_id: credentials["access-token"],
              fullscreen: false,
              autosize: true,
              studies_overrides: {},
              theme: getTheme(selectedTheme),
              overrides: {},
              ...getChartOptionsByProfile(profile),
            };

            tvWidget = new widget(widgetOptions);

            tvWidget.onChartReady(() => {
              const currentTheme = tvWidget.getTheme();
              const theme = getTheme(selectedTheme);
              if (theme !== currentTheme) {
                tvWidget.changeTheme(theme);
              }
              tvWidget.headerReady().then(() => {
                if (!lock) {
                  const detailButton = tvWidget.createButton({
                    align: "right",
                    useTradingViewStyle: false,
                  });

                  detailButton.style.margin = "0";
                  detailButton.style.padding = "0.5rem";

                  detailButton.setAttribute(
                    "title",
                    "Detalhes do ativo, volatilidade, negócios e watchlist"
                  );
                  detailButton.classList.add("apply-common-tooltip");
                  detailButton.addEventListener("click", () => {
                    dispatch(updatePanel({ name: "details", show: true }));
                  });
                  detailButton.innerHTML = "Detalhes";

                  dispatch(updatePanel({ name: "details", render: true }));
                }
              });

              let template = null;
              if (credentials.template) {
                try {
                  template = JSON.parse(credentials.template);
                } catch (e) {}
              }
              const chart = tvWidget.chart();

              //THIS IS A MUMBOJUMBO I KNOW, DO NOT MAKE SENSE
              //BUT AS DATAFEED onResolveSymbol ONLY TRIGGERS WHEN SELECTED INSTRUMENT
              //IS NOT IN THE TV CACHE, IT'S NEEDED TO BE DONE IN ORDER TO UPDATE EXTERNAL SIDE BAR
              chart.onSymbolChanged().subscribe(null, () => {
                if (tvDatafeed._cachedSymbols.includes(chart.symbol())) {
                  tvDatafeed._getInstrumentInfo(chart.symbol());
                }
              });

              if (profile) {
                setupProfile(profile, chart);
                dispatch(
                  updatePanel({ name: "details", show: false, render: false })
                );
              } else {
                if (!template) {
                  chart.createStudy(
                    "Volatilidade Implícita X Histórica",
                    false,
                    false
                  );

                  chart.createStudy(
                    "Directional Movement",
                    false,
                    false,
                    {
                      length: "8", // Length of the study
                      adx: "8", // ADX smoothing parameter
                    },
                    {
                      "+di.color": "#00a18b",
                      "-di.color": "#e65262",
                      "adx.color": "#475e77",
                      "adx.plottype": "circles",
                      "dx.display": 0,
                      "adxr.display": 0,
                      "Overlat.height": 10,
                    }
                  );
                } else {
                  chart.applyStudyTemplate(template);
                }
              }
              _window.oplab.stockchart = tvWidget.activeChart();
            });
          })
          .catch((error) => {
            Browser.deleteCookieByName("access-token");
            localStorage.removeItem("access-token");
            window.location.replace(config.loginURL as string);
          });
      }
    }, 300);

    return () => {
      if (tvWidget) {
        tvWidget.remove();
        delete _window.oplab.stockchart;
      }
    };
  });

  return (
    <div
      ref={chartContainerRef}
      className={`TVChartContainer ${embedded ? "embedded" : "regular"}`}
      style={{ flex: 1 }}
    />
  );
};
