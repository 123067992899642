interface Categories {
  [key: number]: string;
}

export const securityCategories: Categories = {
  0: "undefined",
  1: "BDR",
  2: "COMMON EQUITIES FORWARD",
  3: "ETF DE AÇÕES",
  4: "FORWARD POINTS",
  5: "FORWARD RATE AGREEMENT",
  6: "FUNDOS",
  7: "OPTION ON EQUITIES",
  8: "OPTION ON INDEX",
  9: "RECEBIDOS",
  10: "ROLLOVER",
  11: "AÇÕES",
  12: "RIGHTS",
  13: "UNIT",
  14: "UNIT RECEIPTS",
  15: "VOLATILIDADE",
  16: "ÍNDICE",
  17: "EXERCISE",
  18: "FORW/FUT GOLD",
  20: "FX SWAP",
  21: "ETF FOREIGN INDEX",
  22: "ETF FIXED INCOME",
  23: "GARANTIA",
  25: "IPO - FOLLOW ON",
  26: "LEILÕES",
  30: "BANK CD",
  31: "LETRA DE CREDITO",
  32: "CPR",
  33: "BONDS",
  34: "OURO PURO",
  35: "FIC",
  36: "INTERNATIONAL BONDS",
  37: "DINHEIRO",
  38: "DEBENTURES",
  39: "ADR",
  40: "CRI (Certificados de recebíveis imobiliários)",
  41: "CRA (Certificados de recebíveis do agronegócio)",
  42: "LETRAS FINANCEIRAS",
  43: "NOTAS PROMISSÓRIAS",
  50: "INDICADORES ECONÔMICOS",
  51: "CURVAS DE PREÇO",
  52: "PRICING SURFACES",
  53: "Tradable Sec Lending",
  54: "Sec lending OTC",
  55: "ETF GOVT BONDS",
  56: "ETF PRIMARY MARKET  GROSS SETTLEMENT",
  57: "ETF PRIMARY MARKET NET SETTLEMENT",
  60: "FLEXIBLE EQUITIES FORWARD",
  70: "FIXED INCOME TRADABLE INSTRUMENT T0",
  71: "FIXED INCOME TRADABLE INSTRUMENT T1",
};
