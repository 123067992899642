import "./index.css";

export const Range = (props: any) => {
  const { value } = props;
  const _value = value ?? 100;
  return (
    <div className="range-container">
      <div className="range-bar">
        <div
          className={"circle"}
          style={{ left: `calc(${_value}% - 6px)` }}
        ></div>
      </div>
    </div>
  );
};
