import { api } from '../classes/ServiceProvider'

export const createOrder = api.domain.trade.order.create.bind(api)
export const createOrders = api.domain.trade.order.createMany.bind(api)
export const readOrder = api.domain.trade.order.read.bind(api)
export const updateOrder = api.domain.trade.order.update.bind(api)
export const deleteOrder = api.domain.trade.order.delete.bind(api)
export const fetchOrders = api.domain.trade.order.list.bind(api)
export const countOpenOrders = api.domain.trade.order.countOpen.bind(api)
export const exportHistoricalOrders = api.domain.trade.order.exportHistorical.bind(api)
