import ServiceProvider from '../ServiceProvider'
import { queryBuilder } from '../../utils/queryBuilder'

const baseEndpoint = '/domain'

type SignUp = {
  name: string
  document_type: string
  document_number: string
  email: string
  password: string
  password_confirmation: string
}

type Credential = {
  email: string
  password: string
}

class LegacyService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.signup = this.signup.bind(this)
  }

  async signup(): Promise<any> {
    return (await this.api.post('/users/signup')).data
  }
}

class NotificationPreferenceService {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.get = this.get.bind(this)
    this.save = this.save.bind(this)
  }

  async get(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/notifications/preferences`)).data
  }

  async save(preferences: any): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/notifications/preferences`, preferences)).data
  }
}

class NotificationService {
  api: ServiceProvider
  preferences: NotificationPreferenceService

  constructor(api: ServiceProvider) {
    this.api = api

    this.getAll = this.getAll.bind(this)
    this.get = this.get.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.readAll = this.readAll.bind(this)
    this.getUnreadCount = this.getUnreadCount.bind(this)
    this.preferences = new NotificationPreferenceService(api)
  }

  async getAll(page: string, per: string): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/notifications${queryBuilder({ page, per })}`)).data
  }

  async get(messageId: number): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/notifications/${messageId}`)).data
  }

  async update({ id, ...notification }: { id: number; notification: any }): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/notifications/${id}`, notification)).data
  }

  async delete(notificationId: number): Promise<any> {
    return (await this.api.delete(`${baseEndpoint}/notifications/${notificationId}`)).data
  }

  async readAll(): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/notifications/read_all`)).data
  }

  async deleteAll(): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/notifications/delete_all`)).data
  }

  async getUnreadCount(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/notifications/unread`)).data
  }
}

/**
 * Represents a user in the system.
 */
class User {
  api: ServiceProvider
  notification: NotificationService
  legacy: LegacyService

  constructor(api: ServiceProvider) {
    api.user = this
    this.api = api

    this.getProfile = this.getProfile.bind(this)
    this.savePreferences = this.savePreferences.bind(this)
    this.savePublisherPreferences = this.savePublisherPreferences.bind(this)
    this.authenticate = this.authenticate.bind(this)
    this.authorize = this.authorize.bind(this)
    this.signup = this.signup.bind(this)
    this.findAdvisor = this.findAdvisor.bind(this)
    this.notification = new NotificationService(api)
    this.legacy = new LegacyService(api)
  }

  /**
   * Retrieves the user's profile.
   * @returns A Promise that resolves to the user's profile data.
   */
  async getProfile(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/users/profile`)).data
  }

  /**
   * Saves the user's preferences.
   * @param preferences - The user's preferences.
   * @returns A Promise that resolves to the saved preferences data.
   */
  async savePreferences(preferences: string): Promise<any> {
    return (
      await this.api.post(`${baseEndpoint}/users/settings`, {
        preferences: JSON.stringify(preferences),
      })
    ).data
  }

  /**
   * Saves the user's publisher preferences.
   * @param preferences - The user's publisher preferences.
   * @returns A Promise that resolves to the saved publisher preferences data.
   */
  async savePublisherPreferences(preferences: string): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/users/settings/producer`, preferences)).data
  }

  /**
   * Authenticates the user.
   * @param credential - The user's credential.
   * @returns A Promise that resolves to the authentication result.
   */
  async authenticate(credential: Credential): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/users/authenticate`, credential)).data
  }

  /**
   * Authorizes the user.
   * @returns A Promise that resolves to the authorization result.
   */
  async authorize(): Promise<any> {
    return (await this.api.get(`${baseEndpoint}/users/authorize`)).data
  }

  /**
   * Sends a password reset request for the user.
   * @param email - The user's email.
   * @returns A Promise that resolves to the password reset request result.
   */
  async resetPasswordRequest(email: string): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/password_reset`, { email })).data
  }

  /**
   * Signs up a new user.
   * @param payload - The user's sign up payload.
   * @returns A Promise that resolves to the sign up result.
   */
  async signup(payload: SignUp): Promise<any> {
    return (await this.api.post(`${baseEndpoint}/sign_up`, payload)).data
  }

  /**
   * Updates the user's sensitive data.
   * @param payload - The updated user data.
   * @param id - The user's ID.
   * @returns A Promise that resolves to the updated user data.
   */
  async update(
    payload: {
      name: string
      cpf: string
      phone_area_code: string
      phone_number: string
    },
    id: number,
  ): Promise<any> {
    return (await this.api.put(`${baseEndpoint}/users/${id}/update_sensitive_data`, { ...payload })).data
  }

  /**
   * Finds an advisor based on the given expression.
   * @param expr - The expression to search for an advisor.
   * @returns A Promise that resolves to the advisor id and name.
   */
  async findAdvisor(expr: string): Promise<{ id: number; name: string }[]> {
    return (await this.api.get(`${baseEndpoint}/users/advisors${queryBuilder({ expr })}`)).data
  }
}

export default User
