type Environment = {
  baseURL: string
}

interface ConfEnv {
  [key: string]: Environment
}

const config: ConfEnv = {
  development: {
    baseURL: 'https://lorraine.oplab.com.br/',
  },
  staging: {
    baseURL: 'https://lorraine.oplab.com.br/',
  },
  production: {
    baseURL: 'https://api.oplab.com.br/',
  },
}

export default config[process.env.REACT_APP_ENV || 'development']
