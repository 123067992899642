import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export enum MarketStatus {
  Open = 0,
  Closed = 1,
  Auction = 2,
}

export type Market = {
  status: MarketStatus;
};

export type ApplicationState = {
  instrument: any;
  theme: string;
  panels: {
    details: {
      show: boolean;
      render: boolean;
    };
  };
  watchlist: any;
  market: Market;
};

const initialState: ApplicationState = {
  instrument: {},
  theme: "dark",
  panels: {
    details: {
      show: true,
      render: false,
    },
  },
  watchlist: {},
  market: {
    status: MarketStatus.Closed,
  },
};

const sliceName = "application";

const sortData = (sortType: number, column: string) => {
  return (a: any, b: any) => {
    if (a[column] < b[column]) {
      return sortType * -1;
    }
    if (a[column] > b[column]) {
      return sortType;
    }

    return 0;
  };
};

export const applicationSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    updateTheme: (state: ApplicationState, action: PayloadAction<any>) => {
      state.theme = action.payload;
    },
    fulfillWatchlist: (state: ApplicationState, action: PayloadAction<any>) => {
      state.watchlist =
        action.payload
          .filter((d: any) => {
            return d.is_default;
          })
          .reduce((acc: any, d: any) => {
            return { ...acc, ...d };
          }, {}) ?? {};

      (state?.watchlist?.instruments ?? []).sort(sortData(1, "symbol"));
    },
    updateWatchlistInstruments: (
      state: ApplicationState,
      action: PayloadAction<any>
    ) => {
      const { quotes } = action.payload;
      state.watchlist.instruments.forEach((instrument: any) => {
        const quote = quotes[instrument.symbol];
        if (quote) {
          instrument.price = quote.close;
          instrument.variation = quote.variation;
        }
      });
    },
    sortWatchlistInstruments: (
      state: ApplicationState,
      action: PayloadAction<any>
    ) => {
      const { sortType, column } = action.payload;
      (state?.watchlist?.instruments ?? []).sort(sortData(sortType, column));
    },
    updateInstrument: (state: ApplicationState, action: PayloadAction<any>) => {
      state.instrument = action.payload;
    },
    updateTick: (state: ApplicationState, action: PayloadAction<any>) => {
      const { payload: tick } = action;
      if (tick.symbol === state.instrument.symbol) {
        state.instrument.trade = tick.trade;
        state.instrument.time = tick.time;
        state.instrument.open = tick.open;
        state.instrument.high = tick.high;
        state.instrument.low = tick.low;
        state.instrument.close = tick.close;
        state.instrument.volume = tick.volume;
        state.instrument.financial_volume = tick["financial-volume"];
        state.instrument.variation = tick.variation;
        state.instrument.bid = tick.bid;
        state.instrument.ask = tick.ask;
        state.instrument["bid-volume"] = tick["bid-volume"];
        state.instrument["ask-volume"] = tick["ask-volume"];
      } else if (tick.symbol === state.instrument.symbol + "IVX") {
        state.instrument.iv_current = tick.close;
      }
    },
    updateMarketStatus: (
      state: ApplicationState,
      action: PayloadAction<any>
    ) => {
      const translate = {
        F: MarketStatus.Closed,
        A: MarketStatus.Open,
      };

      state.market.status =
        translate[action.payload.status.phase as keyof typeof translate] ??
        MarketStatus.Closed;
    },
    updatePanel: (state: ApplicationState, action: PayloadAction<any>) => {
      const { name, render, show } = action.payload;
      if (state.panels[name as keyof typeof state.panels]) {
        if (typeof render === "boolean") {
          state.panels[name as keyof typeof state.panels].render = render;
        }
        if (typeof show === "boolean") {
          state.panels[name as keyof typeof state.panels].show = show;
        }
      }
    },
  },
});

export const {
  updateTheme,
  updatePanel,
  updateInstrument,
  updateTick,
  updateMarketStatus,
  fulfillWatchlist,
  updateWatchlistInstruments,
  sortWatchlistInstruments,
} = applicationSlice.actions;

export default applicationSlice.reducer;
