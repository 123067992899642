import { queryBuilder } from '../../utils/queryBuilder'
import ServiceProvider from '../ServiceProvider'

class Pep {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api

    this.list = this.list.bind(this)
  }

  async list(page = 1, limit = 100): Promise<any> {
    return (await this.api.get(`/domain/public/strategies${queryBuilder({ page, limit })}`)).data
  }
}

export default Pep
