import { locale } from "./internationalization";

export const formatNumber = (val, digits = 2, defaultValue = Number.NaN) => {
  return new Intl.NumberFormat(locale, {
    style: "decimal",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(
    val === undefined ||
      val === null ||
      Number.isNaN(val) ||
      !Number.isFinite(val)
      ? defaultValue
      : val
  );
};

export const formatPercent = (val, digits = 2, defaultValue = Number.NaN) => {
  return new Intl.NumberFormat(locale, {
    style: "percent",
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(
    val === undefined ||
      val === null ||
      Number.isNaN(val) ||
      !Number.isFinite(val)
      ? defaultValue
      : val
  );
};
