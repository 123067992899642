import {
  format,
  // fromUnixTime,
  setMilliseconds,
  setSeconds,
  // subHours,
} from "date-fns";
import OHLC from "./ohlc";
import datafeed from "@oplab-team/oplab-datafeed";
import Provider from "./request";

const Datafeeds = {};

Datafeeds.MangoDataFeed = function (options) {
  this._endpoints = options.endpoints;
  this._url = options.url;
  this._socketUrl = options.socketUrl || options.url;
  this._accessToken = options.accessToken;
  this._datafeedToken = options.datafeedToken;
  this._storage = options.storage;
  this._configuration = undefined;
  this._callbacks = {};
  this._subscribers = {};
  this._indicatorParams = {};
  this._lastRequest = { chart: [], tick: [] };
  this._cachedSymbols = [];
  this._charts = {};
  this.serviceProvider = new Provider.ServiceProvider(options);
  this._initialize();
};

// TV Datafeed implementation
Datafeeds.MangoDataFeed.prototype.onReady = async function (callback) {
  const _this = this;

  if (this._configuration) {
    setTimeout(function () {
      callback(_this._configuration);
    }, 0);
  } else {
    this.on("ready", function () {
      callback(_this._configuration);
    });
  }
};

Datafeeds.MangoDataFeed.prototype.searchSymbols = async function (
  userInput,
  exchange,
  symbolType,
  onResultReadyCallback
) {
  if (process.env.REACT_APP_ENV === "development") {
    console.log("[searchSymbols]: Method call");
  }

  const instrument = await this.serviceProvider.searchInstrument(
    userInput,
    exchange,
    symbolType
  );
  if (instrument) {
    onResultReadyCallback(instrument);
  }
};

Datafeeds.MangoDataFeed.prototype.aquireExtraInfo = async function (symbol) {
  if (!symbol) return;
  const data = await this.serviceProvider.getStoredStock(symbol);
  if (data) {
    this._fireEvent("datafeed.instrument.info", data);
  }
};

Datafeeds.MangoDataFeed.prototype._getComposite = function (symbol) {
  const parts = symbol.split(/\+/);
  if (parts.length < 2) return null;
  const composite = {};
  parts.forEach((part) => {
    const f = part.match(/^(.*)\((.*),(.*)\)/);
    composite[f[1]] = { from: f[2].toLowerCase(), to: f[3].toLowerCase() };
  });
  return composite;
};

Datafeeds.MangoDataFeed.prototype.resolveSymbol = async function (
  symbolName,
  onSymbolResolvedCallback,
  onResolveErrorCallback,
  extension
) {
  if (process.env.REACT_APP_ENV === "development") {
    console.log("[resolveSymbol]: Method call", symbolName);
  }
  const s = symbolName.split(/:/);
  if (s.length > 1) symbolName = s[1];

  const composite = this._getComposite(symbolName);
  let ticker = null;
  if (composite) {
    ticker = symbolName;
    symbolName = Object.keys(composite)[0];
  } else {
    this.aquireExtraInfo.call(this, symbolName);
  }

  const data = await this.serviceProvider.getChartInstrumentData(symbolName);

  if (process.env.REACT_APP_ENV === "development") {
    console.log("[resolveSymbol] instrument ", data, composite);
  }
  if (data) {
    if (data.name) {
      onSymbolResolvedCallback(
        ticker ? { ...data, ticker: ticker } : { ...data }
      );

      if (!composite) {
        //THIS IF IS UNECESSARY IN PRINCIPLE DUE TO THIS EVENT WON'T TRIGGER IF SYMBOL IS IN TV CACHE. BUT IF IT CHANGES
        //PREVENT THIS USEFUL FEATURE TO INCREASE THE ARRAY WITH SAME SYMBOL
        if (!this._cachedSymbols.includes(symbolName)) {
          this._cachedSymbols.push(symbolName);
        }
        this._getInstrumentInfo(symbolName);
      }
    } else if (data.message) {
      onResolveErrorCallback(data.message);
    } else {
      onResolveErrorCallback("unknown");
    }
  } else onResolveErrorCallback("not found");
};

Datafeeds.MangoDataFeed.prototype.getBars = async function (
  symbolInfo,
  resolution,
  periodParams,
  onHistoryCallback,
  onErrorCallback
) {
  const { from, to, firstDataRequest } = periodParams;

  if (symbolInfo.ticker.match(/\+/))
    return this._getCompositeBars(
      symbolInfo,
      resolution,
      from,
      to,
      onHistoryCallback,
      onErrorCallback,
      firstDataRequest
    );

  const bars = await this.serviceProvider.getChartBars(
    symbolInfo.ticker,
    resolution,
    format(from, "yyyyMMddHHmm"),
    firstDataRequest ? "" : format(to, "yyyyMMddHHmm")
  );
  if (bars) {
    if (bars.data && bars.data.length > 0) {
      if (firstDataRequest) {
        this.setCurrentBar(symbolInfo.ticker, resolution, bars.data);
      }
      onHistoryCallback(bars.data, { noData: false });
    } else if (bars.message) {
      onErrorCallback(bars.message);
    } else {
      if (firstDataRequest) {
        const first = await this.serviceProvider.getChartBarsSample(
          symbolInfo.ticker,
          resolution
        );
        if (first.data && first.data.length > 0) {
          this.setCurrentBar(symbolInfo.ticker, resolution, first.data);
          onHistoryCallback(first.data, { noData: false });
        } else {
          onHistoryCallback([], { noData: true });
        }
      } else {
        onHistoryCallback([], { noData: true });
      }
    }
  } else onErrorCallback("not found");
};

Datafeeds.MangoDataFeed.prototype.subscribeBars = function (
  symbolInfo,
  resolution,
  onRealtimeCallback,
  subscriberUID,
  onResetCacheNeededCallback
) {
  if (process.env.REACT_APP_ENV === "development") {
    console.log(
      "[subscribeBars]: Method call with subscriberUID:",
      subscriberUID,
      symbolInfo.ticker.toUpperCase()
    );
  }

  const ticker = symbolInfo.ticker.toUpperCase();

  const composite = this._getComposite(ticker);

  if (!this._subscribers[subscriberUID]) {
    this._subscribers[subscriberUID] = {
      symbol: symbolInfo.ticker.toUpperCase(),
      composite: composite,
      resolution: resolution.toUpperCase(),
      onData: onRealtimeCallback,
      onResetNeeded: onResetCacheNeededCallback,
    };
  }

  const symbols = composite ? Object.keys(composite) : [ticker];

  const reqs = [];

  symbols.forEach((symbol) => {
    if (symbol && symbol !== "") {
      datafeed.subscribe("tick", [symbol]);
    }
  });

  this._lastRequest.chart = reqs;
};

Datafeeds.MangoDataFeed.prototype.unsubscribeBars = function (subscriberUID) {
  if (process.env.REACT_APP_ENV === "development") {
    console.log(
      "[unsubscribeBars]: Method call with subscriberUID:",
      subscriberUID
    );
  }
  const _this = this;
  const subscriber = this._subscribers[subscriberUID];
  if (subscriber) {
    const symbols = subscriber.composite
      ? Object.keys(subscriber.composite)
      : [subscriber.symbol];
    symbols.forEach((symbol) => {
      datafeed.unsubscribe("tick", [symbol, symbol + "IVX"]);
      delete _this._charts[symbol];
    });
  }
};

// The following methods are not related to trading view api but
// helpers or extended methods
Datafeeds.MangoDataFeed.prototype._initialize = function () {
  const _this = this;
  this.serviceProvider.getChartDataInfo().then((chartData) => {
    // supported_resolutions: ["1D", "1W", "1M"],
    _this._configuration = {
      ...chartData,
      supports_marks: true,
      supported_resolutions: chartData.supportedResolutions,
      symbols_types: chartData.symbolsTypes,
    };

    _this._initializeSocket();
  });
};

Datafeeds.MangoDataFeed.prototype._initializeSocket = function (reconnect) {
  const _this = this;

  datafeed
    .configure({
      endpoints: this._endpoints,
      accessToken: this._datafeedToken,
    })
    .on("tick", this._handleTick.bind(this))
    .on("system", this._handleStatus.bind(this))
    .on("ready", () => {
      _this._fireEvent("ready");
    })
    .connect();
};

Datafeeds.MangoDataFeed.prototype.on = function (event, callback) {
  if (!this._callbacks.hasOwnProperty(event)) {
    this._callbacks[event] = [];
  }

  this._callbacks[event].push(callback);
  return this;
};

Datafeeds.MangoDataFeed.prototype._fireEvent = function (event, argument) {
  if (this._callbacks.hasOwnProperty(event)) {
    const callbacksChain = this._callbacks[event];
    for (let i = 0; i < callbacksChain.length; ++i) {
      callbacksChain[i](argument);
    }

    // this._callbacks[event] = [];
  }
};

Datafeeds.MangoDataFeed.prototype._getSubscriber = function (
  symbol,
  resolution
) {
  let subscriber = null;
  const subs = this._subscribers;
  Object.keys(subs).forEach((k) => {
    const v = subs[k];
    if (v.resolution === resolution.toUpperCase()) {
      if (
        v.symbol === symbol.toUpperCase() ||
        (v.composite &&
          Object.keys(v.composite).indexOf(symbol.toUpperCase()) >= 0)
      ) {
        subscriber = v;
        return false;
      }
    }
  });

  return subscriber;
};

Datafeeds.MangoDataFeed.prototype._handleChart = function (tick) {
  const ohlc = this._charts[tick.symbol];

  if (!ohlc) return;

  const _date = setMilliseconds(tick.time, 0);
  const __date = setSeconds(_date, 0);
  ohlc.push(
    __date,
    tick.close,
    tick["trade-volume"],
    tick["trade-financial-volume"]
  );

  const chart = ohlc.getBar();

  if (chart.data && chart.data.length > 0) {
    const subscriber = this._getSubscriber(chart.symbol, chart.resolution);

    if (subscriber) {
      let bar = chart.data[0];

      if (subscriber.composite) {
        const cp = subscriber.composite[chart.symbol];

        if (!subscriber.lastBar) {
          subscriber.lastBar = { ...bar };
          Object.keys(subscriber.composite).forEach(
            (e) => (subscriber.lastBar[subscriber.composite[e].to] = null)
          );
        }

        subscriber.lastBar[cp.to] = bar[cp.from];
        subscriber.lastBar.time = bar.time;

        if (
          subscriber.lastBar.open &&
          subscriber.lastBar.high &&
          subscriber.lastBar.low &&
          subscriber.lastBar.close
        ) {
          setTimeout(() => subscriber.onData(subscriber.lastBar), 0);
        }
      } else {
        setTimeout(() => subscriber.onData(bar), 0);
      }
    }
  }
};

Datafeeds.MangoDataFeed.prototype._handleStatus = function (data) {
  this._fireEvent("datafeed.market.status", data);
};

Datafeeds.MangoDataFeed.prototype._handleTick = function (tick) {
  if (!tick.symbol) return;
  this._fireEvent("datafeed.instrument.updateTick", tick);
  this._handleChart(tick);
};

Datafeeds.MangoDataFeed.prototype._getComposite = function (symbol) {
  const parts = symbol.split(/\+/);
  if (parts.length < 2) return null;
  const composite = {};
  parts.forEach((part) => {
    const f = part.match(/^(.*)\((.*),(.*)\)/);
    composite[f[1]] = { from: f[2].toLowerCase(), to: f[3].toLowerCase() };
  });
  return composite;
};

Datafeeds.MangoDataFeed.prototype._getCompositeBars = async function (
  symbolInfo,
  resolution,
  from,
  to,
  onHistoryCallback,
  onErrorCallback,
  firstDataRequest
) {
  const composite = this._getComposite(symbolInfo.ticker);
  const symbols = Object.keys(composite);

  const promises = symbols.map(async (_symbol) => {
    const response = await this.serviceProvider.getSeries(
      _symbol,
      resolution,
      from,
      to,
      firstDataRequest
    );
    const { symbol, data: bars } = response;
    return { symbol, bars };
  });

  const responses = await Promise.all(promises);

  const response = {
    name: "",
    resolution: resolution,
    symbol: symbols.join("+"),
    data: [],
  };

  const series = {};
  const timeSeriesRef = [];
  responses.forEach((chart) => {
    const _bars = chart.bars;
    response.name += " + " + _bars.name;
    if (!series[chart.symbol]) {
      series[chart.symbol] = {};
    }

    for (let i = 0, l = _bars.length; i < l; i++) {
      const _time = _bars[i].time;
      if (!timeSeriesRef.includes(_time)) {
        timeSeriesRef.push(_time);
      }
      series[chart.symbol][_bars[i].time] = _bars[i];
    }
  });

  timeSeriesRef.sort((a, b) => {
    return a - b;
  });

  const l = timeSeriesRef.length;
  for (let i = 0; i < l; i++) {
    const item = {
      time: timeSeriesRef[i],
      open: 0,
      high: 0,
      low: 0,
      close: 0,
      volume: 0,
    };

    symbols.forEach((symbol) => {
      const k = composite[symbol];
      item[k.to] = (series[symbol][timeSeriesRef[i]] || {})[k.from] || 0;
    });
    response.data.push(item);
  }

  if (response) {
    if (response.data) {
      onHistoryCallback.call(this, response.data, { noData: false });
    } else if (response.data.message) {
      onErrorCallback.call(this, response.message);
    } else {
      onHistoryCallback.call(this, [], { noData: true });
    }
  } else onErrorCallback.call(this, "not found");
};

Datafeeds.MangoDataFeed.prototype._getInstrumentInfo = function (symbol) {
  if (process.env.REACT_APP_ENV === "development") {
    console.log("_getInstrumentInfo ", symbol);
  }
  if (!symbol) return;

  this.serviceProvider.getInstrumentInfo(symbol).then((instrument) => {
    this._fireEvent("datafeed.instrument.info", instrument);
  });
};

Datafeeds.MangoDataFeed.prototype.setCurrentBar = function (
  symbol,
  resolution,
  data
) {
  const ohlc = new OHLC(symbol, resolution, data[data.length - 1]);
  this._charts[symbol] = ohlc;
};

Datafeeds.MangoDataFeed.prototype.getServerTime = function (callback) {
  // this.serviceProvider.getServerTime().then((data) => {
  //   if (data && data.now) {
  //     const _now = new Date(data.now).getTime();
  //     callback(fromUnixTime(_now));
  //   }
  // });
};

export default Datafeeds;
