import { api } from '../classes/ServiceProvider'

const notification = api.domain.user.notification
export const NotificationsServices = Object.keys(api.domain.user.notification).reduce(
  (acc, method) => ({
    ...acc,
    [method]: typeof notification[method].bind === 'function' ? notification[method].bind(this) : notification[method],
  }),
  {},
)
