import { configureStore } from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./rootSaga";
import applicationSlice from "../slices/applicationSlice";

const sagaMiddleware = createSagaMiddleware();

const middleware: any[] = [];

middleware.push(sagaMiddleware);

if (process.env.NODE_ENV === "development") {
  middleware.push(createLogger());
}

export const store = configureStore({
  reducer: {
    application: applicationSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false }).concat(middleware),
  devTools: process.env.REACT_APP_ENV !== "production",
});

sagaMiddleware.run(rootSaga);
