import ServiceProvider from '../ServiceProvider'

export interface Advisor {
  id: number
  name: string
  email: string
}
export interface SharedPortfolioResponse {
  id: number
  portfolio_id: number
  advisor: Advisor
  access_level: 'read' | 'write'
  status: 'active' | 'inactive'
  created_at: string
  updated_at: string
  canceled_at: string | null
}

export interface PortfolioEventResponse {
  id: number
  portfolio_id: number
  advisor: Advisor
  eid: string
  details: string
  created_at: string
  updated_at: string
}

class SharedPortfolio {
  api: ServiceProvider

  constructor(api: ServiceProvider) {
    this.api = api
    this.list = this.list.bind(this)
    this.create = this.create.bind(this)
    this.read = this.read.bind(this)
    this.update = this.update.bind(this)
    this.delete = this.delete.bind(this)
    this.listEvents = this.listEvents.bind(this)
    this.getEvent = this.getEvent.bind(this)
    this.update = this.update.bind(this)
  }

  async list(portfolio_id: number): Promise<SharedPortfolioResponse[]> {
    return (await this.api.get(`/domain/portfolios/${portfolio_id}/shared_portfolios`)).data
  }

  async create(
    portfolio_id: number,
    advisor_id: number,
    access_level: 'read' | 'write',
  ): Promise<SharedPortfolioResponse> {
    return (await this.api.post(`/domain/portfolios/${portfolio_id}/shared_portfolios`, { advisor_id, access_level }))
      .data
  }

  async read(portfolio_id: number, id: number): Promise<SharedPortfolioResponse> {
    return (await this.api.post(`/domain/portfolios/${portfolio_id}/shared_portfolios/${id}`)).data
  }

  async update(portfolio_id: number, id: number, access_level: 'read' | 'write'): Promise<SharedPortfolioResponse> {
    return (await this.api.put(`/domain/portfolios/${portfolio_id}/shared_portfolios/${id}`, { access_level })).data
  }

  async delete(portfolio_id: number, id: number): Promise<any> {
    return (await this.api.delete(`/domain/portfolios/${portfolio_id}/shared_portfolios/${id}`)).data
  }

  async listEvents(portfolio_id: number, id: number): Promise<PortfolioEventResponse[]> {
    return (await this.api.get(`/domain/portfolios/${portfolio_id}/shared_portfolios/${id}/events`)).data
  }

  async getEvent(portfolio_id: number, shared_portfolio_id: number, id: number): Promise<PortfolioEventResponse> {
    return (
      await this.api.get(`/domain/portfolios/${portfolio_id}/shared_portfolios/${shared_portfolio_id}/events/${id}`)
    ).data
  }
}

export default SharedPortfolio
