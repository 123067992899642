import { api } from '../classes/ServiceProvider'

export const getDefaultWatchlist = api.domain.watchlist.getDefault.bind(api)
export const addToDefaultWatchlist = api.domain.watchlist.addToDefault.bind(api)
export const removeFromDefaultWatchlist = api.domain.watchlist.removeFromDefault.bind(api)
export const getWatchlists = api.domain.watchlist.list.bind(api)
export const getWatchlist = api.domain.watchlist.get.bind(api)
export const createWatchlist = api.domain.watchlist.create.bind(api)
export const updateWatchlist = api.domain.watchlist.update.bind(api)
export const deleteWatchlist = api.domain.watchlist.delete.bind(api)
export const removeSymbolFromWatchlist = api.domain.watchlist.removeSymbolFrom.bind(api)
export const addSymbolToWatchlist = api.domain.watchlist.addSymbolTo.bind(api)
